import { useEffect, useMemo, useRef } from "react"
import { PurchaseQuotationItem } from "../entities/PurchaseQuotation"
import { useList } from "@refinedev/core"
import { Component } from "../entities/Component"
import { useEditingTable } from "./hooks"
import Table, { ColumnsType } from "antd/es/table"
import { Card, Select } from "antd"
import { NumberEditor } from "./NumberEditor"
import { floatToString } from "./numbers"

interface ComponentsTableItem {
  component: Component
  quantity: number
}

type Props<T> = {
  value?: T[]
  onChange?: (value: T[]) => void
  title?: string
  hideKits?: boolean
  excludeId?: number
  disabled?: boolean
}

export const ComponentsTable = <T extends ComponentsTableItem>({
  value,
  onChange,
  title,
  hideKits,
  excludeId,
  disabled,
}: Props<T>) => {
  const previousCount = useRef<number>(value?.length ?? 0)

  const { data: components, refetch } = useList<Component>({
    resource: "components",
    pagination: { pageSize: 99_999_999 },
  })

  const items = useMemo(
    () =>
      value?.sort((a, z) =>
        a.component.description && z.component.description
          ? a.component.description.localeCompare(z.component.description)
          : 0
      ),
    [value]
  )

  const componentsOptions = useMemo(() => {
    return (
      components?.data
        .filter((component) => !hideKits || !component.kit)
        .filter((component) => !excludeId || component.id !== excludeId)
        .map((component) => ({
          value: component.id,
          label: component.description,
        })) || []
    )
  }, [components?.data, excludeId, hideKits])

  const cloneAndDo = (action: (clone: PurchaseQuotationItem[]) => any) => {
    const itemsClone = structuredClone(items || [])
    const res = action(itemsClone)
    onChange && onChange(itemsClone)

    return res
  }

  const scrollToLastItem = () => {
    const element = document.querySelector(".ant-table-body tr:last-child")
    element?.scrollIntoView()
  }

  useEffect(() => {
    if (items?.length && items?.length > previousCount.current) {
      scrollToLastItem()
      previousCount.current = items?.length
    }
  }, [items?.length])

  const {
    isEditing,
    editingItem,
    updateEditingItem,
    renderTableButtons,
    renderRowButtons,
  } = useEditingTable({
    disabled,
    onAddItem: () =>
      cloneAndDo((itemsClone) => {
        itemsClone.push({
          component: {} as Component,
          quantity: 1,
        } as PurchaseQuotationItem)
        return {
          index: itemsClone.length - 1,
          item: itemsClone[itemsClone.length - 1],
        }
      }),
    onUpdateItem: (item: PurchaseQuotationItem, index: number) => {
      cloneAndDo((itemsClone) => {
        itemsClone[index] = item
      })
    },
    onDeleteItem: (index: number) => {
      cloneAndDo((itemsClone) => {
        itemsClone.splice(index, 1)
      })
    },
    onValidateItem: (item: PurchaseQuotationItem) => !!item.component.id,
  })

  const itemsColumns: ColumnsType<PurchaseQuotationItem> = [
    {
      title: "Material",
      dataIndex: ["component", "description"],
      key: "component",
      render: (value, record, index) => {
        if (!isEditing(index) && !!record.component) {
          return value
        }

        return (
          <Select<any, any>
            style={{ width: "100%" }}
            value={editingItem?.component.id}
            onSelect={(value) => {
              const { id, description } =
                components?.data.find((c) => c.id === value) || {}

              return updateEditingItem({
                component: {
                  id,
                  description,
                } as Component,
              })
            }}
            options={componentsOptions}
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input.toUpperCase())
            }
            showSearch
          />
        )
      },
    },
    {
      title: "Quantidade",
      dataIndex: "quantity",
      key: "quantity",
      width: "10em",
      render: (value, record, index) => {
        if (!isEditing(index)) {
          return floatToString(value)
        }

        return (
          <NumberEditor
            value={editingItem?.quantity}
            onChange={(value) =>
              updateEditingItem({ quantity: value || undefined })
            }
          />
        )
      },
    },
    {
      title: "Ações",
      key: "actions",
      width: "7em",
      render: renderRowButtons(),
    },
  ]

  return (
    <Card size='small' extra={renderTableButtons()} title={title}>
      <Table
        dataSource={items}
        columns={itemsColumns}
        size='small'
        pagination={false}
        scroll={{ y: 350 }}
      />
    </Card>
  )
}

export const componentsTableValidator = async <T extends ComponentsTableItem>(
  rule,
  value: T[]
) => {
  let errors: string[] = []

  if (!value || !value.length) {
    errors.push("Nenhum item informado")
  }

  const findDuplicates = (res, id, index, arr) =>
    res || arr.indexOf(id) !== index

  const hasDuplicatedComponents = value
    .map((i) => i.component?.id)
    .filter(Boolean)
    .reduce(findDuplicates, false)

  if (hasDuplicatedComponents) {
    errors.push("Há materiais duplicados")
  }

  const hasEmptyComponents =
    value.map((i) => Boolean(i.component)).filter((c) => !c).length > 0

  if (hasEmptyComponents) {
    errors.push("Há materiais não informados")
  }

  const hasEmptyQuantities = value
    .map((i) => !i.quantity)
    .reduce((acc, cur) => acc || cur, false)

  if (hasEmptyQuantities) {
    errors.push("Há quantidades não informadas")
  }

  if (errors.length) return Promise.reject(errors)
}
