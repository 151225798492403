import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons"
import { useCustom } from "@refinedev/core"
import { Card, Skeleton, Space, Typography } from "antd"
import dayjs from "dayjs"
import { Audit } from "../../entities/Audit"
import { User } from "../../entities/User"
import { DividerLine } from "../divider-line/DividerLine"
import { RowSpacer } from "../row-spacer/RowSpacer"

interface FirstAndLastAudit {
  first: Audit
  last: Audit
}

type Props = {
  tableName: string
  pk: number
}

export const AuditPanel: React.FC<Props> = ({ tableName, pk }) => {
  const { data, isLoading } = useCustom<FirstAndLastAudit>({
    url: "audit",
    method: "get",
    config: {
      query: {
        tableName,
        pk,
        all: "false",
      },
    },
  })

  const audit = data?.data

  const fmtTime = (date?: Date) => {
    return date ? dayjs(date).format("DD/MM/YYYY HH:mm") : "..."
  }

  const fullName = (user?: User) => {
    return user ? `${user.firstName} ${user.lastName ?? ""}`.trim() : "..."
  }

  const innerInfo = (audit?: Audit) => {
    if (isLoading) {
      return <Skeleton active paragraph={true} avatar={false} title={false} />
    }

    if (!audit) {
      return (
        <Typography.Text>
          <ClockCircleOutlined /> Não há informações de auditoria
        </Typography.Text>
      )
    }

    return (
      <Space direction='vertical'>
        <Typography.Text>
          <ClockCircleOutlined /> {fmtTime(audit.date)}
        </Typography.Text>
        <Typography.Text>
          <UserOutlined /> {fullName(audit.user)}
        </Typography.Text>
      </Space>
    )
  }

  return (
    <>
      <DividerLine title='Auditoria' />

      <RowSpacer spans={[12, 12]}>
        <Card title='Criação' size='small'>
          {innerInfo(audit?.first)}
        </Card>
        <Card title='Última Modificação' size='small'>
          {innerInfo(audit?.last)}
        </Card>
      </RowSpacer>
    </>
  )
}
