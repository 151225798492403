import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons"
import { Button, Card, Input, Modal, Row, Space, Tree, Typography } from "antd"
import { useMemo, useState } from "react"
import { ProductComponent } from "../../../entities/Product"
import { generateTreeDatasource } from "./helpers"
import { PCWrapper } from "./pcwrapper"

type Props = {
  wrapper: PCWrapper
  selectedKey: string
  onChange: (pc: ProductComponent[]) => void
  onSelectKey: (key: string) => void
}

export const LocationTree: React.FC<Props> = ({
  wrapper,
  onChange,
  selectedKey,
  onSelectKey,
}) => {
  const [treeFilter, setTreeFilter] = useState("")
  const [editingKey, setEditingKey] = useState("")
  const [editingTitle, setEditingNewTitle] = useState("")

  const treeDataSource = useMemo(
    () => generateTreeDatasource(wrapper, treeFilter),
    [wrapper, treeFilter]
  )

  const handleOnSelect = (_, { node }) => {
    if (node?.key !== selectedKey) {
      onSelectKey(node?.key)
      handleCancelEdit()
    }
  }

  const handleSearch = (value: string) => setTreeFilter(value)

  const handleEditClick = () => {
    const { part, place } = wrapper.splitKey(selectedKey)

    setEditingKey(selectedKey)
    setEditingNewTitle(place || part)
  }

  const handleCancelEdit = () => setEditingKey("")

  const handleConfirmEdit = () => {
    onChange(wrapper.update(selectedKey, editingTitle))

    // Creates the new selectedKey, based on what has changed (part or place)
    // todo: move to wrapper?
    const { part, place } = wrapper.splitKey(selectedKey)
    const newSelectedKey = place ? `${part}::${editingTitle}` : editingTitle

    handleOnSelect(null, { node: { key: newSelectedKey } })
  }

  const handleInputKeyDown = (e) => {
    if (e.code === "Escape") handleCancelEdit()
    if (e.code === "Enter") handleConfirmEdit()
  }

  const handleInputChange = (e) => {
    setEditingNewTitle(`${e.target.value}`.toUpperCase())
  }

  const handleNewPartClick = () => {
    onChange(wrapper.addNewPart())
  }

  const handleNewPlaceClick = () => {
    onChange(wrapper.addNewPlace(selectedKey))
  }

  const handleDeleteClick = () => {
    const { part, place } = wrapper.splitKey(selectedKey)
    const tag = place ? "neste local" : "nesta parte"

    const componentsInside = wrapper.componentsInside({ part, place })

    const remove = () => {
      onChange(wrapper.remove({ part, place }))
    }

    if (componentsInside.length) {
      Modal.confirm({
        title: `Tem certeza que deseja excluir?`,
        icon: <ExclamationCircleFilled />,
        content: (
          <span>
            Ainda há{" "}
            <Typography.Text type='danger' strong>
              {componentsInside.length} materiais
            </Typography.Text>{" "}
            {tag}!
          </span>
        ),
        onOk: remove,
      })
    } else {
      remove()
    }
  }

  const canEdit = !!selectedKey
  const canDelete = (({ part, place }) => part || place)(
    wrapper.splitKey(selectedKey)
  )

  return (
    <Card
      title='Locais'
      size='small'
      extra={
        <Row>
          <Button
            icon={<PlusOutlined />}
            title='Nova Parte'
            onClick={handleNewPartClick}
          />
          <Button
            icon={<PlusCircleOutlined />}
            title='Novo Local'
            onClick={handleNewPlaceClick}
          />
          <Button
            icon={<EditOutlined />}
            title='Editar descrição'
            disabled={!canEdit}
            onClick={handleEditClick}
          />
          <Button
            icon={<DeleteOutlined />}
            title='Remover'
            disabled={!canDelete}
            onClick={handleDeleteClick}
          />
        </Row>
      }
    >
      <Row>
        <Input.Search
          allowClear
          placeholder='Pesquisar'
          onSearch={handleSearch}
        />
      </Row>
      <Row
        style={{ overflowY: "scroll", minHeight: "20em", maxHeight: "20em" }}
      >
        <Tree
          treeData={treeDataSource}
          onSelect={handleOnSelect}
          onDoubleClick={handleEditClick}
          showLine
          defaultExpandAll
          titleRender={({ title, key }) => {
            const edit = key === editingKey

            if (edit) {
              return (
                <Space.Compact size={"small"} style={{ width: "100%" }}>
                  <Input.TextArea
                    value={editingTitle}
                    onChange={handleInputChange}
                    autoFocus
                    onFocus={(e) => e.target.select()}
                    onKeyDown={handleInputKeyDown}
                    rows={2}
                    style={{ textTransform: "uppercase" }}
                  />
                  <Button
                    icon={<CheckOutlined />}
                    onClick={handleConfirmEdit}
                  />
                  <Button icon={<CloseOutlined />} onClick={handleCancelEdit} />
                </Space.Compact>
              )
            }
            return <span>{title}</span>
          }}
        />
      </Row>
    </Card>
  )
}
