import { Form } from "antd"
import { ComponentsInnerForm } from "./Form"
import { ProductComponent } from "../../../entities/Product"
import { PCWrapper } from "./pcwrapper"

type Props2 = {
  disabled?: boolean
}
export const ComponentsForm: React.FC<Props2> = ({ disabled }) => {
  const validator = (rule, value: ProductComponent[]) => {
    const wrapper = new PCWrapper(value)
    const error = wrapper.validate()

    if (error) return Promise.reject(error)

    return Promise.resolve()
  }

  return (
    <Form.Item name='components' rules={[{ validator }]}>
      <ComponentsInnerForm disabled={disabled} />
    </Form.Item>
  )
}
