import { Form, FormProps, Input, Tabs } from "antd"
import TabPane from "antd/es/tabs/TabPane"
import dayjs from "dayjs"
import React from "react"
import { Order } from "../../entities/Order"
import { useQueryParam } from "../../helpers/hooks"
import { RowSpacer } from "../row-spacer/RowSpacer"
import { AccessoriesForm } from "./AccessoriesForm"
import { GeneralInfoPanel } from "./GeneralInfoPanel"
import { PurchaseQuotationPanel } from "./PurchaseQuotationPanel"

type Props = {
  formProps: FormProps<Order>
  disabled?: boolean
}

export const OrdersForm: React.FC<Props> = ({ formProps, disabled }) => {
  const [activeKey, setActiveKey] = useQueryParam("tab", "generalInfo")
  const order = formProps.initialValues as Order
  const showPurchaseQuotations = disabled && order?.status.id === 2
  const showAccessories = !disabled

  const focusAccessories = () => setActiveKey("accessories")

  return (
    <Form {...formProps} layout='vertical'>
      <Form.Item name={["id"]} hidden>
        <Input readOnly />
      </Form.Item>
      <RowSpacer spans={[4, 6, 6, 8]}>
        <Form.Item
          label='Código'
          name={["code"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item label='Status' name={["status", "name"]}>
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label='Status de Produção'
          name={["productionStatus", "name"]}
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label='Data de Aprovação'
          name='startDate'
          getValueProps={(value) => ({
            value: value ? dayjs(value).format("DD/MM/YYYY") : "-",
          })}
        >
          <Input readOnly />
        </Form.Item>
      </RowSpacer>
      <Tabs
        activeKey={activeKey}
        onChange={(activeKey) => setActiveKey(activeKey)}
      >
        <TabPane key={"generalInfo"} tab={"Informações Gerais"}>
          <GeneralInfoPanel
            order={order}
            disabled={disabled}
            onFocusAccessories={focusAccessories}
          />
        </TabPane>

        {showPurchaseQuotations && (
          <TabPane key={"purchaseQuotation"} tab={"Cotações"} forceRender>
            <PurchaseQuotationPanel order={order} disabled={disabled} />
          </TabPane>
        )}

        {showAccessories && (
          <TabPane key='accessories' tab='Acessórios' forceRender>
            <AccessoriesForm />
          </TabPane>
        )}
      </Tabs>
    </Form>
  )
}
