import { MaskedInput } from "antd-mask-input";
import { cnpj, cpf } from "cpf-cnpj-validator";
import React from "react";

export const renderCnpjCpf = (value: string) => {
  if (!value) return undefined;

  const isCpf = value.length === 11;

  return isCpf ? cpf.format(value) : cnpj.format(value);
};

export const renderPhoneNumber = (value: string) => {
  const formatter = [
    (value: string) => value.replace(/^(\d{2})(\d{1})(\d{4})(\d{4}).*/, '($1) $2 $3-$4'),
    (value: string) => value.replace(/^(\d{2})(\d{4})(\d{4}).*/, '($1) $2-$3'),
  ]

  if (!value) return '';

  return value.length >= 11 ? formatter[0](value) : formatter[1](value)
}

export const PhoneMaskedInput: React.FC<{ value?: any, onChange?: any, disabled?: boolean }> = (props) => {
  const mask = React.useMemo(
    () => [
      {
        mask: "(00) 0000-0000",
        lazy: true,
      },
      {
        mask: "(00) 0 0000-0000",
        lazy: true,
      },
    ],
    []
  );

  const chooseMask = (appended: string, masked: IMask.MaskedDynamic) => {
    const length = masked.unmaskedValue.length + appended.length;
    return length <= 10 ? masked.compiledMasks[0] : masked.compiledMasks[1];
  };

  return (
    <MaskedInput
      {...props}
      mask={mask}
      maskOptions={{ dispatch: chooseMask }}
    />
  )
}