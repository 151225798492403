import { Divider } from "antd"

type Props = {
  title: string
}

export const DividerLine: React.FC<Props> = ({ title }) => (
  <Divider orientation='left' orientationMargin={0}>
    {title}
  </Divider>
)
