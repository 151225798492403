import { Edit, useForm } from "@refinedev/antd"
import {
  IResourceComponentsProps,
  useGetIdentity,
  useResource,
} from "@refinedev/core"
import React from "react"
import { User } from "../../entities/User"
import { UsersForm } from "./UsersForm"

export const UsersEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading } = useForm()
  const { id } = useResource()
  const { data: user } = useGetIdentity<User>()
  const isMe = id === user?.id.toString()

  return (
    <Edit saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <UsersForm formProps={formProps} isMe={isMe} showPasswordFields />
    </Edit>
  )
}
