import { axiosInstance } from "../axiosInstance"

type ReturnType = { id: string; username: string }[]

export const usersUsingUsername = (username: string, excludeId?: number) =>
  axiosInstance.get<ReturnType>(`/users/byUsername/${username}`, {
    params: {
      excludeId,
    },
  })
