import { Form, Input, Select } from "antd"
import { MaskedInput } from "antd-mask-input"
import DisabledContext from "antd/es/config-provider/DisabledContext"
import { useWatch } from "antd/es/form/Form"
import useFormInstance from "antd/es/form/hooks/useFormInstance"
import { RowSpacer } from "components/row-spacer/RowSpacer"
import { cnpj, cpf } from "cpf-cnpj-validator"
import { ValidatorRule } from "rc-field-form/lib/interface"
import React, { useCallback, useContext, useEffect, useMemo } from "react"
import { customersUsingCnpjCpf } from "../../../service/customers"

type IdentificationPanelProps = {}

export const IdentificationForm: React.FC<IdentificationPanelProps> = () => {
  const { setFieldValue } = useFormInstance()
  const disabled = useContext(DisabledContext)

  const id = useWatch(["id"])
  const type = useWatch(["type"])

  useEffect(() => {
    setFieldValue("type", "individual")
  }, [setFieldValue])

  const fieldDef = useMemo(() => {
    switch (type) {
      case "business":
        return {
          cnpjCpf: {
            label: "CNPJ",
            validator: (value: string) => cnpj.isValid(value),
            message: "CNPJ inválido",
            mask: "00.000.000/0000-00",
            format: (value: string) => cnpj.format(value),
          },
          ieRg: {
            label: "Inscrição Estadual",
          },
        }
      default:
        return {
          cnpjCpf: {
            label: "CPF",
            validator: (value: string) => cpf.isValid(value),
            message: "CPF inválido",
            mask: "000.000.000-00",
            format: (value: string) => cpf.format(value),
          },
          ieRg: {
            label: "RG",
          },
        }
    }
  }, [type])

  const cnpjCpfValidator: ValidatorRule["validator"] = useCallback(
    async (rule, value, callback) => {
      if (fieldDef && value) {
        if (!fieldDef.cnpjCpf.validator(value)) {
          return Promise.reject(fieldDef.cnpjCpf.message)
        }

        try {
          const { data } = await customersUsingCnpjCpf(value, id)

          if (data.length) {
            return Promise.reject(
              `Este ${fieldDef.cnpjCpf.label.toLocaleLowerCase()} já está associado a outro cliente`
            )
          }
        } catch (e) {
          console.error(e)
          return Promise.reject("Erro ao validar o cnpj")
        }
      }
    },
    [fieldDef, id]
  )

  return (
    <RowSpacer spans={[4, 20, /**/ 4, 10, 10]}>
      <Form.Item label='Id' name={["id"]}>
        <Input readOnly disabled />
      </Form.Item>
      <Form.Item
        label='Razão Social'
        name={["companyName"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input autoFocus style={{ textTransform: "uppercase" }} />
      </Form.Item>
      <Form.Item
        label='Pessoa'
        name={["type"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          options={[
            { value: "individual", label: "Física" },
            { value: "business", label: "Jurídica" },
          ]}
        />
      </Form.Item>
      <Form.Item
        label={fieldDef?.cnpjCpf.label}
        name={["cnpjCpf"]}
        dependencies={["type"]}
        rules={[
          {
            required: true,
          },
          {
            validator: cnpjCpfValidator,
          },
        ]}
        getValueProps={(value) => ({ value: fieldDef.cnpjCpf.format(value) })}
      >
        <MaskedInput mask={fieldDef?.cnpjCpf.mask || ""} disabled={disabled} />
      </Form.Item>
      <Form.Item
        label={fieldDef?.ieRg.label}
        name={["ieRg"]}
        dependencies={["type"]}
      >
        <Input style={{ textTransform: "uppercase" }} />
      </Form.Item>
    </RowSpacer>
  )
}
