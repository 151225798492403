import { Edit, useForm } from "@refinedev/antd"
import { IResourceComponentsProps } from "@refinedev/core"
import React from "react"
import { OrderStatus } from "../../entities/OrderStatus"
import { OrdersStatusesForm } from "./OrdersStatusesForm"

export const OrdersStatusEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<any, any, OrderStatus>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <OrdersStatusesForm formProps={formProps} />
    </Edit>
  )
}
