import { Col, Row } from "antd";
import React, { Children, PropsWithChildren } from "react";

type Props = {
  spans?: number[] | "left";
};

export const RowSpacer: React.FC<PropsWithChildren<Props>> = ({
  spans,
  children,
}) => {
  const leftAlign = spans === "left";
  const xs = leftAlign ? undefined : 24;
  const sm = (index: number) =>
    leftAlign ? undefined : spans ? spans[index] : undefined;

  return (
    <Row gutter={24}>
      {Children.map(children, (child, index) => (
        child && <Col xs={xs} md={sm(index)}>
          {child}
        </Col>
      ))}
    </Row>
  );
};
