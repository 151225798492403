import { BackwardOutlined } from "@ant-design/icons"
import { useCan } from "@refinedev/core"
import { Button, ButtonProps, FormInstance, Popconfirm } from "antd"

export const RevertButton: React.FC<{ form: FormInstance }> = ({ form }) => {
  return (
    <Popconfirm
      title='Reverter as alterações?'
      description='Todas as alterações feitas serão perdidas!'
      onConfirm={() => form?.resetFields()}
    >
      <Button icon={<BackwardOutlined />}>Reverter</Button>
    </Popconfirm>
  )
}

interface ActionButtonProps extends ButtonProps {
  resource: string
  action: string
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  resource,
  action,
  ...props
}) => {
  const { data } = useCan({
    resource,
    action,
  })

  return <Button {...props} disabled={!data?.can} />
}
