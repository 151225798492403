import { Show } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import React from "react";
import { Accessory } from "../../entities/Accessory";
import { useShowForm } from "../../helpers/useShowForm";
import { AccessoriesForm } from "./AccessoriesForm";

export const AccessoriesShow: React.FC<IResourceComponentsProps> = () => {
  const { formProps, formLoading } = useShowForm<Accessory>();

  return (
    <Show isLoading={formLoading}>
      <AccessoriesForm formProps={formProps} disabled />
    </Show>
  );
};
