import { Show } from "@refinedev/antd"
import { IResourceComponentsProps } from "@refinedev/core"
import React from "react"
import { Product } from "../../entities/Product"
import { useShowForm } from "../../helpers/useShowForm"
import { ProductsForm } from "./ProductsForm"

export const ProductsShow: React.FC<IResourceComponentsProps> = () => {
  const { formProps, formLoading } = useShowForm<Product>()

  return (
    <Show isLoading={formLoading}>
      <ProductsForm formProps={formProps} disabled />
    </Show>
  )
}
