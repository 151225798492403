import { axiosInstance } from "../axiosInstance"
import { ApiResponse } from "../types"

type LoginParams = {
  username: string
  password: string
}

type LoginResponse = ApiResponse & {
  accessToken: string
  refreshToken: string
}

export const login = async ({
  username,
  password,
}: LoginParams): Promise<LoginResponse> => {
  const response = await axiosInstance.post<LoginResponse>("/auth/login", {
    username,
    password,
  })

  if (response.status === 201) {
    return Promise.resolve(response.data)
  }

  return Promise.reject(response.statusText)
}
