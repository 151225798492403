import { Order } from "../../entities/Order"
import { axiosInstance } from "../axiosInstance"

export const advanceOrder = (order: Order) => {
  return axiosInstance.post<Order>(`/orders/advance/${order.id}`)
}

type ReturnType = { id: string; companyName: string }[]

export const ordersUsingCode = (code: string, excludeId?: number) =>
  axiosInstance.get<ReturnType>(`/orders/byCode/${code}`, {
    params: {
      excludeId,
    },
  })
