import { Show } from "@refinedev/antd"
import { IResourceComponentsProps } from "@refinedev/core"
import React from "react"
import { ComponentGroup } from "../../entities/ComponentGroup"
import { useShowForm } from "../../helpers/useShowForm"
import { ComponentsGroupsForm } from "./ComponentsGroupsForm"

export const ComponentsGroupsShow: React.FC<IResourceComponentsProps> = () => {
  const { formProps, formLoading } = useShowForm<ComponentGroup>()

  return (
    <Show isLoading={formLoading}>
      <ComponentsGroupsForm formProps={formProps} disabled />
    </Show>
  )
}
