import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons"
import { Show, useForm } from "@refinedev/antd"
import { IResourceComponentsProps, useShow } from "@refinedev/core"
import { Form, Input, Table, Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import React, { useMemo } from "react"
import ReactJsonView from "react-json-view"
import { Audit, AuditDetail } from "../../entities/Audit"
import { RowSpacer } from "../row-spacer/RowSpacer"

export const AuditLogShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<Audit>({
    resource: "audit",
  })

  const { formProps } = useForm({})

  const data = queryResult.data?.data
  const mainTable = (tableName: string) => tableName === data?.tableName

  const columns: ColumnsType<AuditDetail> = [
    {
      title: "Tabela",
      dataIndex: "tableName",
      key: "tableName",
      render: (value) => (
        <Tag color={mainTable(value) ? "success" : "default"}>{value}</Tag>
      ),
      width: "15em",
    },
    {
      title: "Operação",
      dataIndex: "operation",
      key: "operation",
      render: (value) => {
        const icon =
          value === "UPDATE" ? (
            <EditOutlined />
          ) : value === "INSERT" ? (
            <PlusCircleOutlined />
          ) : value === "DELETE" ? (
            <DeleteOutlined />
          ) : null

        return (
          <>
            {icon} {value}
          </>
        )
      },
      width: "15em",
    },
    {
      title: "Mudança",
      dataIndex: "diff",
      key: "diff",
      render: (value) => <DataViewer value={value} />,
    },
  ]
  const dataSource = useMemo(() => {
    if (!data) return []

    const sortDetail = (a: AuditDetail, z: AuditDetail) => {
      const mainTableFirst = a.tableName === data.tableName ? -99999 : 0
      const byOperation = a.operation.localeCompare(z.operation)
      const byTableName = a.tableName.localeCompare(z.tableName)

      return mainTableFirst || byTableName || byOperation
    }

    return data.detail.sort(sortDetail).map((detail, index) => ({
      key: index,
      ...detail,
      diff: {
        ...(["UPDATE"].includes(detail.operation)
          ? { changes: detail.diff || "no changes" }
          : {}),
        ...(["INSERT", "UPDATE"].includes(detail.operation)
          ? { new: detail.newValue }
          : {}),
        ...(["DELETE", "UPDATE"].includes(detail.operation)
          ? { old: detail.oldValue }
          : {}),
      },
    }))
  }, [data])

  return (
    <Show isLoading={queryResult.isLoading}>
      <Form
        {...formProps}
        initialValues={{
          ...data,
          date: dayjs(data?.date).format("DD/MM/YYYY HH:mm"),
        }}
        layout='vertical'
      >
        <RowSpacer spans={[7, 2, 4, 7, 4]}>
          <Form.Item label='Entidade' name='tableName'>
            <Input readOnly />
          </Form.Item>
          <Form.Item label='PK' name='pk'>
            <Input readOnly />
          </Form.Item>
          <Form.Item label='Operação' name='operation'>
            <Input readOnly />
          </Form.Item>
          <Form.Item label='Usuário' name={["user", "username"]}>
            <Input readOnly />
          </Form.Item>
          <Form.Item label='Data' name='date'>
            <Input readOnly />
          </Form.Item>
        </RowSpacer>
        <Form.Item label='Atualizações no banco de dados'>
          <Table dataSource={dataSource} columns={columns} />
        </Form.Item>
      </Form>
    </Show>
  )
}

const DataViewer: React.FC<{ value?: any }> = ({ value }) =>
  value && <ReactJsonView src={value} collapsed={1} />
