import { Edit, SaveButton, useForm } from "@refinedev/antd"
import { IResourceComponentsProps } from "@refinedev/core"
import React from "react"
import { Product } from "../../entities/Product"
import { RevertButton } from "../../helpers/buttons"
import { ProductsForm } from "./ProductsForm"

export const ProductsEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading, queryResult, form } =
    useForm<Product, any, Product>()

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      isLoading={formLoading}
      footerButtons={[
        <RevertButton form={form} />,
        <SaveButton onClick={() => form.submit()} />,
      ]}
    >
      <ProductsForm formProps={formProps} product={queryResult?.data?.data} />
    </Edit>
  )
}
