import { CreateButton, List, RefreshButton } from "@refinedev/antd"
import { IResourceComponentsProps, useList } from "@refinedev/core"
import React from "react"
import { Order } from "../../../entities/Order"
import { OrderStatus } from "../../../entities/OrderStatus"
import { OrderBoard } from "./components"

export const OrdersKanban: React.FC<IResourceComponentsProps> = () => {
  const {
    refetch,
    data: orders,
    isLoading: ordersLoading,
  } = useList<Order>({
    resource: "orders",
  })

  const { data: ordersStatuses, isLoading: ordersStatusesLoading } =
    useList<OrderStatus>({
      resource: "ordersStatuses",
    })

  return (
    <>
      <List
        headerButtonProps={{ size: "large" }}
        headerButtons={[
          <RefreshButton onClick={() => refetch()} />,
          <CreateButton resource='orders' />,
        ]}
      >
        <OrderBoard
          orders={orders?.data ?? []}
          ordersStatuses={ordersStatuses?.data || []}
          loading={ordersLoading || ordersStatusesLoading}
        />
      </List>
    </>
  )
}
