import { CreateButton, List, ShowButton, useTable } from "@refinedev/antd"
import { BaseRecord, IResourceComponentsProps } from "@refinedev/core"
import { Space, Table } from "antd"
import dayjs from "dayjs"
import React from "react"
import { SearchForm, onSearch } from "../search-form/SearchForm"

export const AuditLogList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable({
    resource: "audit/log",
    syncWithLocation: true,
    onSearch,
  })

  return (
    <List
      headerButtons={[<SearchForm {...searchFormProps} />, <CreateButton />]}
    >
      <Table {...tableProps} rowKey='id'>
        <Table.Column dataIndex='tableName' title='Tabela' sorter />
        <Table.Column dataIndex='pk' title='PK' sorter />
        <Table.Column dataIndex='operation' title='Operação' />
        <Table.Column dataIndex={["user", "username"]} title='Usuário' />
        <Table.Column
          dataIndex='date'
          title='Data'
          render={(value) =>
            dayjs(value).format("DD/MM/YYYY HH:MM").toUpperCase()
          }
        />
        <Table.Column
          title='Actions'
          dataIndex='actions'
          width='10rem'
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton hideText size='small' recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
