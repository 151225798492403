import { useList } from "@refinedev/core"
import { Checkbox, Form, FormProps, Tag } from "antd"
import { Accessory } from "../../entities/Accessory"
import { Product, ProductAccessory } from "../../entities/Product"
import {
  AccessoriesTable,
  AccessoriesTableValidator,
} from "../accessories-table/AccessoriesTable"

type Props = {
  formProps: FormProps<Product>
  disabled?: boolean
}

export const AccessoriesForm: React.FC<Props> = ({ formProps, disabled }) => {
  return (
    <Form.Item
      id='accessories'
      name={"accessories"}
      rules={[
        {
          validator: AccessoriesTableValidator,
        },
      ]}
    >
      <AccessoriesTableFormItem disabled={disabled} />
    </Form.Item>
  )
}

type ItemsTableProps = {
  value?: ProductAccessory[]
  onChange?: (value: ProductAccessory[]) => void
  disabled?: boolean
}

const AccessoriesTableFormItem: React.FC<ItemsTableProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const { data: accessories } = useList<Accessory>({
    resource: "accessories",
    pagination: { pageSize: 99_999_999 },
  })

  return (
    <AccessoriesTable
      disabled={disabled}
      value={value}
      onChange={onChange}
      options={accessories?.data}
      template={
        { accessory: {} as Accessory, included: true } as ProductAccessory
      }
      onValidateItem={(item) => {
        return !!item.accessory?.id
      }}
      valueColumnTitle='Incluso'
      renderValueColumnShow={(record) =>
        record.included ? (
          <Tag color='success'>Incluso</Tag>
        ) : (
          <Tag color='default'>Opcional</Tag>
        )
      }
      renderValueColumnEdit={(record, onChange) => (
        <Checkbox
          checked={record?.included}
          onChange={(e) => onChange({ included: e.target.checked || false })}
          style={{ width: "100%" }}
        />
      )}
    />
  )
}
