import {
  DeleteOutlined,
  MenuOutlined,
  PlusOutlined,
  UndoOutlined,
} from "@ant-design/icons"
import { DndContext, DragEndEvent } from "@dnd-kit/core"
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { useList } from "@refinedev/core"
import { Button, Card, Modal, RowProps, Select, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import React, { useCallback, useMemo, useState } from "react"
import { Component } from "../../../entities/Component"
import { ProductComponent } from "../../../entities/Product"
import { NumberEditor } from "../../../helpers/NumberEditor"
import { PCWrapper } from "./pcwrapper"

type Props = {
  wrapper: PCWrapper
  selectedKey: string
  onChange: (pc: ProductComponent[]) => void
  disabled?: boolean
}

export const ComponentsTable: React.FC<Props> = ({
  wrapper,
  onChange,
  selectedKey,
  disabled,
}) => {
  const [processing, setProcessing] = useState(false)

  const { data: components, refetch } = useList<Component>({
    resource: "components",
    pagination: { pageSize: 99_999_999 },
  })

  const componentsOptions = useMemo(() => {
    return (
      components?.data.map((component) => ({
        value: component.id,
        label: component.description,
      })) || []
    )
  }, [components?.data])

  const process = async (doWork: () => Promise<void>) => {
    setProcessing(true)

    try {
      await doWork()
    } finally {
      setProcessing(false)
    }
  }

  const tableDataSource = useMemo(
    () => wrapper.productComponentsFromKey(selectedKey),
    [selectedKey, wrapper]
  )

  const handleChange = useCallback(
    (record: ProductComponent) => {
      process(async () => {
        const dataToChange = wrapper.toArray()
        const pos = dataToChange.findIndex((pc) => pc.id === record.id)
        dataToChange.splice(pos, 1, record)
        onChange(dataToChange)
      })
    },
    [onChange, wrapper]
  )

  const handleDelete = useCallback(
    async (record: ProductComponent) => {
      if (record.component?.id) {
        const cancel = await new Promise((resolve) => {
          Modal.confirm({
            title: "Excluir este material?",
            onOk: resolve,
          })
        })

        if (cancel) return Promise.resolve()
      }

      await process(async () => {
        const dataToChange = wrapper.toArray()
        const last = wrapper.productComponentsFromKey(selectedKey).length === 1
        const pos = dataToChange.findIndex((pc) => pc.id === record.id)

        if (last) {
          dataToChange.splice(pos, 1, {
            ...record,
            component: undefined,
            quantity: undefined,
          })
        } else {
          dataToChange.splice(pos, 1)
        }
        onChange(dataToChange)
      })
    },
    [wrapper, selectedKey, onChange]
  )

  const COLUMNS: ColumnsType<ProductComponent> = [
    {
      key: "sort",
      width: "1em",
    },
    {
      title: "Material",
      dataIndex: ["component", "description"],
      key: "component",
      render: (_: any, record: ProductComponent) => {
        return (
          <Select<any, any>
            style={{ width: "100%" }}
            value={record.component?.id}
            onSelect={(value) => {
              const { id, description } =
                components?.data.find((c) => c.id === value) || {}

              return handleChange({
                ...record,
                component: {
                  id,
                  description,
                } as Component,
              })
            }}
            options={componentsOptions}
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input.toUpperCase())
            }
            showSearch
            disabled={disabled}
          />
        )
      },
    },
    {
      title: "Quantidade",
      dataIndex: ["quantity"],
      key: "quantity",
      width: "10em",
      render: (_: any, record: ProductComponent) => (
        <NumberEditor
          value={record.quantity}
          onChange={(value: number) =>
            handleChange({
              ...record,
              quantity: value,
            })
          }
          disabled={disabled}
        />
      ),
    },
    {
      title: "",
      render: (_, record: ProductComponent, index: number) => (
        <>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record)}
            disabled={disabled}
          />
        </>
      ),
    },
  ]

  const handleNewClick = () => {
    process(async () => {
      onChange(wrapper.addNewProduct(selectedKey))
    })
  }

  const handleComponentsReloadClick = () => refetch()

  const Row = ({ children, ...props }: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    })

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 }
      )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
      transition,
      ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    }

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === "sort") {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{ touchAction: "none", cursor: "move" }}
                  {...listeners}
                />
              ),
            })
          }
          return child
        })}
      </tr>
    )
  }

  const isPartOnly = wrapper.keyIsPartOnly(selectedKey)

  const pathLabel = useMemo(() => {
    const { part, place } = wrapper.splitKey(selectedKey)
    return isPartOnly ? `${part} (Resumo)` : `${part} / ${place}`
  }, [isPartOnly, selectedKey, wrapper])

  const canNew = !isPartOnly

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      process(async () => {
        const data = wrapper.toArray()

        const activeIndex = data.findIndex((i) => i.id === active.id)
        const overIndex = data.findIndex((i) => i.id === over?.id)
        const newData = arrayMove<ProductComponent>(
          data,
          activeIndex,
          overIndex
        )

        onChange(newData)
      })
    }
  }
  return (
    <Card
      title={pathLabel}
      size='small'
      style={{ height: "calc(100%)" }}
      extra={
        <Row>
          <Button
            icon={<PlusOutlined />}
            title='Novo material'
            disabled={!canNew || processing || disabled}
            onClick={handleNewClick}
          />
          <Button
            icon={<UndoOutlined />}
            title='Recarrega a lista de componentes disponíveis'
            onClick={handleComponentsReloadClick}
            disabled={disabled}
          />
        </Row>
      }
    >
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={tableDataSource.map((pc) => pc.id)}
          strategy={verticalListSortingStrategy}
          disabled={disabled}
        >
          <div
            style={{
              overflowY: "scroll",
              minHeight: "22em",
              maxHeight: "22em",
            }}
          >
            <Table
              size='small'
              columns={COLUMNS}
              dataSource={tableDataSource}
              pagination={false}
              style={{ color: "silver" }}
              components={{
                body: {
                  row: Row,
                },
              }}
              rowKey='id'
            />
          </div>
        </SortableContext>
      </DndContext>
    </Card>
  )
}
