import { axiosInstance } from "../axiosInstance"
import { ApiResponse } from "../types"

type RefreshParams = {
  refreshToken: string
}

type RefreshResponse = ApiResponse & {
  accessToken: string
  refreshToken: string
}

export const refresh = async ({
  refreshToken,
}: RefreshParams): Promise<RefreshResponse> => {
  const response = await axiosInstance.post<RefreshResponse>(
    "/auth/refresh",
    {
      refreshToken,
    },
    {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
      skipAuthRefresh: true,
    } as any
  )

  if (response.status === 201) {
    return Promise.resolve(response.data)
  }

  return Promise.reject(response.statusText)
}
