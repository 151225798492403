import { Show } from "@refinedev/antd"
import { IResourceComponentsProps } from "@refinedev/core"
import React from "react"
import { Component } from "../../entities/Component"
import { useShowForm } from "../../helpers/useShowForm"
import { ComponentsForm } from "./ComponentsForm"

export const ComponentsShow: React.FC<IResourceComponentsProps> = () => {
  const { formProps, formLoading } = useShowForm<Component>()

  return (
    <Show isLoading={formLoading}>
      <ComponentsForm formProps={formProps} disabled />
    </Show>
  )
}
