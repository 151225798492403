import { Create, useForm } from "@refinedev/antd"
import { IResourceComponentsProps } from "@refinedev/core"
import React from "react"
import { ComponentGroup } from "../../entities/ComponentGroup"
import { ComponentsGroupsForm } from "./ComponentsGroupsForm"

export const ComponentsGroupsCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const { formProps, saveButtonProps, formLoading } = useForm<
    any,
    any,
    ComponentGroup
  >()

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <ComponentsGroupsForm formProps={formProps} />
    </Create>
  )
}
