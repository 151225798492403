import { ArrowRightOutlined } from "@ant-design/icons"
import { EditButton, ListButton, Show } from "@refinedev/antd"
import {
  IResourceComponentsProps,
  useNotification,
  useTranslate,
} from "@refinedev/core"
import axios from "axios"
import { PurchaseQuotation } from "../../entities/PurchaseQuotation"
import { useShowForm } from "../../helpers/useShowForm"
import { advancePurchaseQuotation } from "../../service/purchaseQuotations"
import { PurchaseQuotationsForm } from "./PurchaseQuotationsForm"
import { ActionButton } from "../../helpers/buttons"

export const PurchaseQuotationsShow: React.FC<
  IResourceComponentsProps
> = () => {
  const { formProps, formLoading, refetch } = useShowForm<PurchaseQuotation>()
  const { open } = useNotification()
  const translate = useTranslate()

  const purchaseQuotation = formProps.initialValues as PurchaseQuotation

  const handleAdvance = async () => {
    if (purchaseQuotation) {
      try {
        await advancePurchaseQuotation(purchaseQuotation)
        refetch()
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.data["code"]) {
            return (
              open &&
              open({
                description: translate(
                  `errorCodes.${error.response?.data["code"]}.description`
                ),
                type: "error",
                message: translate(
                  `errorCodes.${error.response?.data["code"]}.message`
                ),
              })
            )
          }

          open &&
            open({
              message: error.message,
              type: "error",
            })
        }
      }
    }
  }

  const AdvanceButton = (
    <ActionButton
      icon={<ArrowRightOutlined />}
      type='primary'
      resource='purchaseQuotations'
      action='advance'
      disabled={!purchaseQuotation?.canAdvance}
      onClick={handleAdvance}
    >
      Avançar
    </ActionButton>
  )
  return (
    <Show
      isLoading={formLoading}
      headerButtons={[<ListButton />, <EditButton />, AdvanceButton]}
    >
      <PurchaseQuotationsForm
        formProps={formProps}
        purchaseQuotation={purchaseQuotation}
        disabled={true}
      />
    </Show>
  )
}
