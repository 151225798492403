import {
  ICommand,
  TextState,
  TextAreaTextApi,
  selectWord,
} from "@uiw/react-md-editor";

export const underline: ICommand = {
  name: "underline",
  keyCommand: "underline",
  shortcuts: "ctrlcmd+u",
  buttonProps: {
    "aria-label": "Add underlined text",
    title: "Add underlined text",
  },
  icon: (
    <svg viewBox="0 0 384 512" width="12" height="12">
      <path
        d="M 58.449 30.594 L 102.87 30.594 L 102.87 240.202 C 102.87 277.128 109.325 303.752 122.235 320.075 C 135.144 336.382 155.823 344.535 184.272 344.535 C 212.721 344.535 233.325 336.382 246.085 320.075 C 258.845 303.752 265.225 277.128 265.225 240.202 L 265.225 30.594 L 309.646 30.594 L 309.646 245.752 C 309.646 290.675 299.09 324.677 277.978 347.757 C 256.865 370.854 225.63 382.402 184.272 382.402 C 142.914 382.402 111.604 370.854 90.342 347.757 C 69.08 324.677 58.449 290.675 58.449 245.752 L 58.449 30.594 Z"
        style={{ paintOrder: "fill", fill: "rgba(0, 0, 0, 0.78)" }}
      ></path>
      <rect
        x="8"
        y="456.785"
        width="368"
        height="44.459"
        style={{ stroke: "rgb(0, 0, 0)", fill: "rgba(0, 0, 0, 0.76)" }}
      ></rect>
    </svg>
  ),
  execute: (state: TextState, api: TextAreaTextApi) => {
    // Adjust the selection to encompass the whole word if the caret is inside one
    const newSelectionRange = selectWord({
      text: state.text,
      selection: state.selection,
    });
    const state1 = api.setSelectionRange(newSelectionRange);
    // Replaces the current selection with the bold mark up
    const state2 = api.replaceSelection(`<u>${state1.selectedText}<u>`);
    // Adjust the selection to not contain the **
    api.setSelectionRange({
      start: state2.selection.end - 3 - state1.selectedText.length,
      end: state2.selection.end - 3,
    });
  },
};
