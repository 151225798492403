import { Card, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import { useMemo } from "react"
import { ProductComponent } from "../../../entities/Product"
import { PCWrapper } from "./pcwrapper"
import { floatToString } from "../../../helpers/numbers"

type Props = {
  wrapper: PCWrapper
  selectedKey: string
  onChange: (pc: ProductComponent[]) => void
}

export const SummaryComponents: React.FC<Props> = ({
  wrapper,
  onChange,
  selectedKey,
}) => {
  const tableDataSource = useMemo(() => {
    return wrapper.groupComponentsByPart(selectedKey)
  }, [wrapper, selectedKey])

  const COLUMNS: ColumnsType<ProductComponent> = [
    {
      title: "Material",
      dataIndex: ["component", "description"],
      key: "component",
      width: "70%",
    },
    {
      title: "Quantidade",
      dataIndex: ["quantity"],
      key: "quantity",
      render: (value: number) => floatToString(value),
    },
  ]

  const pathLabel = useMemo(() => {
    const { part } = wrapper.splitKey(selectedKey)
    return `${part} (Resumo)`
  }, [selectedKey, wrapper])

  return (
    <Card title={pathLabel} size='small' style={{ height: "calc(100%)" }}>
      <div
        style={{
          overflowY: "scroll",
          minHeight: "22em",
          maxHeight: "22em",
        }}
      >
        <Table
          size='small'
          columns={COLUMNS}
          dataSource={tableDataSource}
          pagination={false}
          style={{ color: "silver" }}
        />
      </div>
    </Card>
  )
}
