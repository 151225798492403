import {
  Collapse,
  Descriptions,
  Form,
  Select,
  Input,
  Card,
  Steps,
  Space,
  Skeleton,
  Tag,
  Row,
  Col,
  Button,
} from "antd"
import DescriptionsItem from "antd/es/descriptions/Item"
import React from "react"
import { Link } from "react-router-dom"
import { renderPhoneNumber, renderCnpjCpf } from "../../helpers/formatters"
import { AuditPanel } from "../audit-panel/AuditPanel"
import { RowSpacer } from "../row-spacer/RowSpacer"
import { Order } from "../../entities/Order"
import { useProductColorSelect, useTimelineSteps } from "../../helpers/hooks"
import { useList } from "@refinedev/core"
import { OrderStatus } from "../../entities/OrderStatus"
import { ProductionStatus } from "../../entities/ProductionStatus"
import { Accessory } from "../../entities/Accessory"
import { EditOutlined } from "@ant-design/icons"

type Props = {
  order?: Order
  disabled?: boolean
  onFocusAccessories: () => void
}

export const GeneralInfoPanel: React.FC<Props> = ({
  order,
  disabled,
  onFocusAccessories,
}) => {
  const { data: ordersStatuses } = useList<OrderStatus>({
    resource: "ordersStatuses",
  })

  const { data: productionStatuses } = useList<ProductionStatus>({
    resource: "productionStatuses",
  })

  const productColorsSelectProps = useProductColorSelect()
  const timeline = useTimelineSteps(
    order,
    ordersStatuses?.data,
    productionStatuses?.data
  )

  return (
    <RowSpacer spans={[16, 8]}>
      <RowSpacer spans={[24, 24, 24]}>
        <Collapse
          size='small'
          defaultActiveKey={
            disabled ? ["customer", "accessories"] : ["product", "accessories"]
          }
        >
          <Collapse.Panel
            header={
              <>
                Cliente:{" "}
                <Link to={`/customers/show/${order?.customer?.id}`}>
                  {order?.customer?.companyName}
                </Link>
              </>
            }
            key='customer'
          >
            <Descriptions size='small' bordered column={1}>
              <DescriptionsItem label='Telefone'>
                {renderPhoneNumber(order?.customer?.phoneNumber || "")}
              </DescriptionsItem>
              <DescriptionsItem label='Localidade'>
                {order?.customer?.addressCity}
                {order?.customer?.addressState
                  ? ` / ${order?.customer?.addressState}`
                  : ""}
              </DescriptionsItem>
              <DescriptionsItem label='Documento'>
                {renderCnpjCpf(order?.customer?.cnpjCpf || "")}
              </DescriptionsItem>
            </Descriptions>
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <>
                Produto:{" "}
                <Link to={`/products/show/${order?.product?.id}`}>
                  {order?.product?.name}
                </Link>
              </>
            }
            key='product'
          >
            <Descriptions size='small' bordered column={1}>
              <DescriptionsItem label='Modelo'>
                {order?.product?.name}
              </DescriptionsItem>
              <DescriptionsItem label='Cor'>
                <Form.Item name={["color", "id"]} noStyle>
                  <Select
                    {...productColorsSelectProps}
                    style={{ width: "100%" }}
                    disabled={disabled}
                  />
                </Form.Item>
              </DescriptionsItem>
              <DescriptionsItem label='Número de série'>
                <Form.Item name='serialNumber' noStyle>
                  <Input
                    disabled={disabled}
                    style={{ textTransform: "uppercase" }}
                  />
                </Form.Item>
              </DescriptionsItem>
              <DescriptionsItem label='Comandos'>
                <Form.Item name='controls' noStyle>
                  <Input
                    disabled={disabled}
                    style={{ textTransform: "uppercase" }}
                  />
                </Form.Item>
              </DescriptionsItem>
              <DescriptionsItem label='Tipo de Controle'>
                <Form.Item name='controlType' noStyle>
                  <Select
                    style={{ width: "100%" }}
                    disabled={disabled}
                    options={[
                      { value: "manual", label: "Manual" },
                      { value: "automatic", label: "Automático" },
                    ]}
                  />
                </Form.Item>
              </DescriptionsItem>
            </Descriptions>
          </Collapse.Panel>
          <Collapse.Panel header={"Acessórios"} key='accessories'>
            <Form.Item name='accessories' noStyle>
              <AccessoriesSelect
                onFocusAccessories={onFocusAccessories}
                canFocusAccessories={!disabled}
              />
            </Form.Item>
          </Collapse.Panel>
        </Collapse>
        <RowSpacer>
          {order && <AuditPanel tableName='orders' pk={order.id} />}
        </RowSpacer>
      </RowSpacer>
      <Form.Item>
        <Card title='Linha do tempo' size='small'>
          {timeline ? (
            <Steps direction='vertical' size='small' items={timeline} />
          ) : (
            <Card style={{ minHeight: "20em" }} bordered={false} size='small'>
              {new Array(6).fill(1).map(() => (
                <Space style={{ marginBottom: "1em" }}>
                  <Skeleton.Avatar size='small' />
                  <Skeleton.Input size='small' />
                </Space>
              ))}
            </Card>
          )}
        </Card>
      </Form.Item>
    </RowSpacer>
  )
}

type AccessoriesSelectProps = {
  onFocusAccessories: () => void
  value?: Accessory[]
  canFocusAccessories?: boolean
}

const AccessoriesSelect: React.FC<AccessoriesSelectProps> = ({
  value: pa,
  onFocusAccessories,
  canFocusAccessories,
}) => (
  <Row>
    <Col flex='1'>
      <Space size='small' wrap>
        {pa?.map((p) => (
          <Tag style={{ fontSize: "1em" }} color='geekblue'>
            {p.name}
          </Tag>
        ))}
      </Space>
    </Col>
    <Col>
      {canFocusAccessories && (
        <Button
          icon={<EditOutlined />}
          onClick={onFocusAccessories}
          title={'Alterna para a aba "Acessórios"'}
        />
      )}
    </Col>
  </Row>
)
