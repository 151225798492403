import { axiosInstance } from "../axiosInstance"

type ReturnType = { id: string; companyName: string }[]

export const customersUsingCnpjCpf = (cnpjCpf: string, excludeId?: number) =>
  axiosInstance.get<ReturnType>(
    `/customers/byCnpjCpf/${cnpjCpf.replace(/[^\d]/g, "")}`,
    {
      params: {
        excludeId,
      },
    }
  )
