import { CloseCircleOutlined } from "@ant-design/icons"
import { ErrorComponent, notificationProvider } from "@refinedev/antd"
import "@refinedev/antd/dist/reset.css"
import { Authenticated, CanAccess, I18nProvider, Refine } from "@refinedev/core"
import routerProvider, {
  CatchAllNavigate,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6"
import { ConfigProvider, Empty } from "antd"
import { AuthForm as AuthPage } from "components/auth/AuthForm"
import { ColorModeContextProvider } from "contexts"
import { useTranslation } from "react-i18next"
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom"
import { accessControlProvider } from "./accessControlProvider"
import { authProvider } from "./authProvider"
import { Layout } from "./components/layout"
import { resources } from "./resources"
import { dataProvider } from "./rest-data-provider"
import locale from "antd/locale/pt_BR"
import "dayjs/locale/pt-br"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { useTokensRefresher } from "./service/axiosInstance/interceptors/refreshToken"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale("pt-br")
dayjs.tz.guess()

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: "${label} é obrigatório",
  email: {
    format: "Email inválido",
  },
}

function App() {
  useTokensRefresher()

  const { t, i18n } = useTranslation()

  const i18nProvider: I18nProvider = {
    translate: t,
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  }

  return (
    <BrowserRouter>
      <ColorModeContextProvider>
        <ConfigProvider
          locale={locale}
          form={{ validateMessages }}
          renderEmpty={() => (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description='Não há dados disponíveis'
            />
          )}
          theme={{
            components: {
              Layout: {
                colorBgHeader: "#014a7f",
                colorBgLayout: "yellow",
              },
              Menu: {
                colorSubItemBg: "#005b9d",
              },
            },
            token: {
              colorPrimary: "#005b9d",
            },
          }}
        >
          <Refine
            dataProvider={dataProvider(process.env.PUBLIC_URL)}
            routerProvider={routerProvider}
            notificationProvider={notificationProvider}
            i18nProvider={i18nProvider}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
            resources={resources.map((resource) => ({
              name: resource.name,
              list: `/${resource.name}`,
              edit: `/${resource.name}/edit/:id`,
              show: `/${resource.name}/show/:id`,
              create: `/${resource.name}/create`,
              meta: resource.meta,
              canDelete: false,
              icon: resource.icon,
            }))}
            authProvider={authProvider}
            accessControlProvider={accessControlProvider}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to='/login' />}>
                    <Layout>
                      <CanAccess
                        fallback={
                          <div style={{ fontSize: "1.4em" }}>
                            <CloseCircleOutlined /> Não autorizado
                          </div>
                        }
                      >
                        <Outlet />
                      </CanAccess>
                    </Layout>
                  </Authenticated>
                }
              >
                <Route index element={<Navigate to={"/kanban"} />} />

                {resources.map((resource) => (
                  <Route path={`/${resource.name}`}>
                    <Route index element={resource.components.list} />
                    <Route
                      path={`show/:id`}
                      element={resource.components.show}
                    />
                    <Route
                      path={`edit/:id`}
                      element={resource.components.edit}
                    />
                    <Route
                      path={`create`}
                      element={resource.components.create}
                    />
                  </Route>
                ))}

                <Route path='*' element={<ErrorComponent />} />
              </Route>

              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <Navigate to={"/kanban"} />
                  </Authenticated>
                }
              >
                <Route path='/login' element={<AuthPage />} />
              </Route>
            </Routes>
            <UnsavedChangesNotifier />
          </Refine>
        </ConfigProvider>
      </ColorModeContextProvider>
    </BrowserRouter>
  )
}

export default App
