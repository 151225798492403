import Input from "antd/es/input/Input"
import i18n from "../i18n"

export const translatePurchaseQuotationStatus = (status?: string) => {
  if (!status) return undefined

  const translate = i18n.t
  return translate(`purchaseQuotations.values.status.${status}`)
}

type Props = {
  value?: string
}

export const PurchaseQuotationStatusInput: React.FC<Props> = ({ value }) => (
  <Input value={translatePurchaseQuotationStatus(value)} />
)
