// https://stackoverflow.com/a/64767873/862072

const Allowed = {
  Uppers: "ASDQWE",
  Lowers: "asdqwe",
  Numbers: "234",
  Symbols: "!@",
}

export const getRandomCharFromString = (str) =>
  str.charAt(Math.floor(Math.random() * str.length))

/**
 * the generated password will be @param length, which default to 8,
 * and will have at least one upper, one lower, one number and one symbol
 * @param {number} length - password's length
 * @returns a generated password
 */
export const generatePassword = (length = 8) => {
  let pwd = ""
  pwd += getRandomCharFromString(Allowed.Uppers) // pwd will have at least one upper
  pwd += getRandomCharFromString(Allowed.Lowers) // pwd will have at least one lower
  pwd += getRandomCharFromString(Allowed.Numbers) // pwd will have at least one number
  pwd += getRandomCharFromString(Allowed.Symbols) // pwd will have at least one symbol
  for (let i = pwd.length; i < length; i++)
    pwd += getRandomCharFromString(Object.values(Allowed).join("")) // fill the rest of the pwd with random characters
  return pwd
}
