import { Col, Form, FormProps, Input, Row, Tabs } from "antd"
import { Product } from "../../entities/Product"
import { useTabLabel } from "../../helpers/TabLabel"
import { AuditPanel } from "../audit-panel/AuditPanel"
import { RowSpacer } from "../row-spacer/RowSpacer"
import { AccessoriesForm } from "./AccessoriesForm"
import { OrdersStatusesForm } from "./OrdersStatusesForm"
import { SpecificationForm } from "./SpecificationForm"
import { ComponentsForm } from "./ComponentsForm"

type Props = {
  formProps: FormProps<Product>
  disabled?: boolean
  product?: Product
}
export const ProductsForm: React.FC<Props> = ({
  formProps,
  disabled,
  product,
}) => {
  const { onFieldsChanged, renderTabLabel } = useTabLabel(formProps?.form)

  return (
    <Form {...formProps} layout='vertical' onFieldsChange={onFieldsChanged}>
      <Row gutter={24}>
        <Col xs={24} sm={4}>
          <Form.Item label='Id' name={["id"]}>
            <Input readOnly disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={20}>
          <Form.Item
            label='Nome'
            name={["name"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              autoFocus
              style={{ textTransform: "uppercase" }}
              readOnly={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <RowSpacer>
        <Tabs
          items={[
            {
              key: "1",
              label: renderTabLabel("Especificação", ["specification"]),
              children: <SpecificationForm disabled={disabled} />,
              forceRender: true,
            },
            {
              key: "2",
              label: renderTabLabel("Acessórios", ["accessories"]),
              children: (
                <AccessoriesForm formProps={formProps} disabled={disabled} />
              ),
              forceRender: true,
            },
            {
              key: "3",
              label: renderTabLabel("Prazos", ["orderStatuses"]),
              children: (
                <OrdersStatusesForm formProps={formProps} disabled={disabled} />
              ),
              forceRender: true,
            },
            {
              key: "4",
              label: renderTabLabel("Materiais", ["components"]),
              children: <ComponentsForm disabled={disabled} />,
              forceRender: true,
            },
          ]}
        />
      </RowSpacer>
      <RowSpacer>
        {formProps.initialValues && (
          <AuditPanel tableName='products' pk={formProps.initialValues.id} />
        )}
      </RowSpacer>
    </Form>
  )
}
