import { Create, useForm } from "@refinedev/antd"
import dayjs from "dayjs"
import { ValidatorRule } from "rc-field-form/lib/interface"

import {
  IResourceComponentsProps,
  useNavigation,
  useResource,
} from "@refinedev/core"
import {
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
} from "antd"
import React, { useState } from "react"
import { Order } from "../../entities/Order"
import { ordersUsingCode } from "../../service/orders"
import { RowSpacer } from "../row-spacer/RowSpacer"
import {
  useCustomerSelect,
  useProductColorSelect,
  useProductSelect,
  useStatusSelect,
} from "../../helpers/hooks"
import { InfoCircleFilled } from "@ant-design/icons"

export const OrdersCreate: React.FC<IResourceComponentsProps> = () => {
  const [legacy, setLegacy] = useState(false)

  const { edit } = useNavigation()
  const { resource } = useResource()

  const { formProps, saveButtonProps } = useForm<Order, any, Order>({
    redirect: false,
    onMutationSuccess: (data) => edit(resource!, data.data.id),
  })

  const customerSelectProps = useCustomerSelect()
  const productSelectProps = useProductSelect()
  const statusSelectProps = useStatusSelect()
  const productColorsSelectProps = useProductColorSelect()

  const codeValidator: ValidatorRule["validator"] = async (
    rule,
    value: string
  ) => {
    if (value) {
      try {
        if (!value.match(/\d+-\d+/)) {
          return Promise.reject(`Formato inválido`)
        }

        const { data } = await ordersUsingCode(value, undefined)

        if (data.length) {
          return Promise.reject(`Código em uso`)
        }
      } catch (e) {
        console.error(e)
        return Promise.reject("Erro ao validar o código")
      }
    }
  }

  const startDateValidator: ValidatorRule["validator"] = async (
    rule,
    value
  ) => {
    if (value) {
      const date = dayjs(value)

      if (date.isAfter(dayjs(), "day")) {
        return Promise.reject("Data no futuro")
      }
    }
  }

  const handleLegacyChange = (checked: boolean) => setLegacy(checked)

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout='vertical'>
        <RowSpacer spans={[4, 8]}>
          <Form.Item
            label='Código'
            name={["code"]}
            rules={[
              {
                required: true,
              },
              {
                validator: codeValidator,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Cliente'
            name={["customer", "id"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select {...customerSelectProps} />
          </Form.Item>
        </RowSpacer>
        <RowSpacer spans={[20, 4, 8, 8, 8]}>
          <Form.Item
            label='Produto'
            name={["product", "id"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select showSearch {...productSelectProps} />
          </Form.Item>
          <Form.Item
            label='Cor'
            name='color'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select showSearch allowClear {...productColorsSelectProps} />
          </Form.Item>

          <Form.Item label='Número de série' name={["serialNumber"]}>
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>

          <Form.Item label='Comandos' name={["controls"]}>
            <Input style={{ textTransform: "uppercase" }} />
          </Form.Item>

          <Form.Item label='Tipo de Controle' name={["controlType"]}>
            <Select
              options={[
                { value: "manual", label: "Manual" },
                { value: "automatic", label: "Automático" },
              ]}
            />
          </Form.Item>
        </RowSpacer>

        <Card
          size='small'
          title={
            <Space>
              <Switch onChange={handleLegacyChange} />
              <Typography.Text>
                <Space>
                  Pedido já em andamento
                  <Tooltip title='Indica que o pedido sendo criado já se encontrava em andamento antes da implantação do sistema.'>
                    <InfoCircleFilled />
                  </Tooltip>
                </Space>
              </Typography.Text>
            </Space>
          }
        >
          <RowSpacer spans={[4, 16, 4]}>
            <Form.Item
              label='Data de Aprovação'
              name={"startDate"}
              rules={[
                {
                  required: legacy,
                },
                {
                  validator: startDateValidator,
                },
              ]}
            >
              <DatePicker
                disabled={!legacy}
                format='DD/MM/YYYY'
                style={{ width: "100%" }}
                disabledDate={(date: dayjs.Dayjs) => date.isAfter(dayjs())}
              />
            </Form.Item>
            <Form.Item
              label={
                <Space>
                  Status atual
                  <Tooltip title='Status em que o pedido se encontra atualmente. Os status anteriores serão marcados como "não registrados".'>
                    <InfoCircleFilled />
                  </Tooltip>
                </Space>
              }
              name={["startStatus", "id"]}
              rules={[{ required: legacy }]}
            >
              <Select showSearch {...statusSelectProps} disabled={!legacy} />
            </Form.Item>
            <Form.Item
              label={
                <Space>
                  Entrou em
                  <Tooltip title='Data em que o pedido entrou no status atual, definido no campo ao lado'>
                    <InfoCircleFilled />
                  </Tooltip>
                </Space>
              }
              name={["startStatusDate"]}
              rules={[{ required: legacy }]}
            >
              <DatePicker
                disabled={!legacy}
                format={"DD/MM/YYYY"}
                style={{ width: "100%" }}
                disabledDate={(date: dayjs.Dayjs) => date.isAfter(dayjs())}
              />
            </Form.Item>
          </RowSpacer>
        </Card>
      </Form>
    </Create>
  )
}
