import { ClearOutlined } from "@ant-design/icons"
import { CrudFilters } from "@refinedev/core"
import { Button, Form, FormProps, Input } from "antd"

export const onSearch = ({ search }: any): CrudFilters => [
  { field: "search", operator: "eq", value: search },
]

export const SearchForm: React.FC<FormProps<string>> = (
  searchFormProps: FormProps<string>
) => {
  const handleClear = () => {
    searchFormProps?.form?.resetFields()
    searchFormProps?.form?.submit()
  }

  return (
    <Form {...searchFormProps} layout='inline'>
      <Form.Item name='search'>
        <Input placeholder='Procurar' />
      </Form.Item>
      <Form.Item>
        <Button>
          <ClearOutlined onClick={handleClear} />
        </Button>
      </Form.Item>
    </Form>
  )
}
