import { CrudSorting } from "@refinedev/core"

export const generateSort = (sorters?: CrudSorting) => {
  if (sorters && sorters.length > 0) {
    const sort: string[] = []
    const order: string[] = []

    sorters.forEach((item) => {
      sort.push(item.field)
      order.push(item.order.toUpperCase())
    })

    return {
      sort,
      order,
    }
  }

  return
}
