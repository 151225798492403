import { Form, Tabs } from "antd"
import { FormProps } from "antd/es/form/Form"
import { RowSpacer } from "components/row-spacer/RowSpacer"
import { AuditPanel } from "../../audit-panel/AuditPanel"
import { AddressForm } from "./AddressForm"
import { ContactForm } from "./ContactForm"
import { IdentificationForm } from "./IdentificationForm"

type Props<T> = {
  auditTableName: string
  formProps: FormProps
  disabled?: boolean
  entity?: T
}

export const PartnersForm = <T extends unknown>({
  auditTableName,
  formProps,
  disabled,
  entity,
}: Props<T>) => (
  <Form {...formProps} layout='vertical' disabled={disabled}>
    {/* Identificação */}
    <IdentificationForm />

    <Tabs
      items={[
        { key: "1", label: "Contato", children: <ContactForm /> },
        { key: "2", label: "Endereço", children: <AddressForm /> },
      ]}
    />

    {/* Auditoria */}
    <RowSpacer>
      {formProps.initialValues && (
        <AuditPanel
          tableName={auditTableName}
          pk={formProps.initialValues.id}
        />
      )}
    </RowSpacer>
  </Form>
)
