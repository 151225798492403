import { Show } from "@refinedev/antd"
import { IResourceComponentsProps } from "@refinedev/core"
import React from "react"
import { OrderStatus } from "../../entities/OrderStatus"
import { useShowForm } from "../../helpers/useShowForm"
import { OrdersStatusesForm } from "./OrdersStatusesForm"

export const OrdersStatusesShow: React.FC<IResourceComponentsProps> = () => {
  const { formProps, formLoading } = useShowForm<OrderStatus>()

  return (
    <Show isLoading={formLoading}>
      <OrdersStatusesForm formProps={formProps} disabled />
    </Show>
  )
}
