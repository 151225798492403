import axios from "axios"
import { addAccessTokenInterceptor } from "./interceptors/accessToken"
import { addErrorInterceptor } from "./interceptors/error"
import { addRefreshTokenInterceptor } from "./interceptors/refreshToken"

const axiosInstance = axios.create()

axiosInstance.defaults.baseURL = `${window.location.origin}/api/`

addAccessTokenInterceptor(axiosInstance)
addRefreshTokenInterceptor(axiosInstance)
addErrorInterceptor(axiosInstance)

export { axiosInstance }
