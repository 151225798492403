import { FormatPainterOutlined } from "@ant-design/icons"
import {
  Button,
  Form,
  FormProps,
  Input,
  InputNumber,
  Popover,
  Space,
} from "antd"
import React, { useState } from "react"
import { SketchPicker } from "react-color"
import { OrderStatus } from "../../entities/OrderStatus"
import { RowSpacer } from "../row-spacer/RowSpacer"

type Props = {
  formProps: FormProps<OrderStatus>
  disabled?: boolean
}
export const OrdersStatusesForm: React.FC<Props> = ({
  formProps,
  disabled,
}) => {
  return (
    <Form {...formProps} layout='vertical'>
      <RowSpacer spans={[4, 4, 16, 4, 4, 4]}>
        <Form.Item
          label='Id'
          name={["id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label='Ordem'
          name={["sequence"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label='Nome'
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label='Prazo Padrão (dias)'
          name={["defaultDeadline"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber min={0} disabled={disabled} />
        </Form.Item>

        <Form.Item
          label='Cor'
          name={["color"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ColorPicker disabled={disabled} />
        </Form.Item>
      </RowSpacer>
    </Form>
  )
}

type CustomFormItemProps = {
  value?: string
  onChange?: (color: string) => void
  disabled?: boolean
}

const ColorPicker: React.FC<CustomFormItemProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const [open, setOpen] = useState(false)

  const handleSelectColor = (color: string) => {
    if (onChange) onChange(color)
  }

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  const picker = (
    <SketchPicker
      color={value}
      onChangeComplete={({ hex }) => handleSelectColor(hex)}
      disableAlpha
    />
  )

  return (
    <>
      <Space size={"small"}>
        <Input
          value={value}
          disabled={disabled}
          prefix={
            <div
              style={{
                height: "100%",
                width: "3ch",
                borderRadius: "5px",
                backgroundColor: value,
              }}
            ></div>
          }
        />
        {disabled || (
          <Popover
            content={picker}
            open={open}
            overlayInnerStyle={{ padding: 0 }}
            onOpenChange={handleOpenChange}
            trigger={"click"}
          >
            <Button icon={<FormatPainterOutlined />} disabled={disabled} />
          </Popover>
        )}
      </Space>
    </>
  )
}
