import { HttpError } from "@refinedev/core"
import { AxiosInstance } from "axios"
import i18n from "../../../i18n"

const errorInterceptor = (error) => {
  const code = error.response.data.code

  const customError: HttpError = {
    ...error,
    message: code
      ? i18n.t(`errorCodes.${code}`)
      : error.response?.data?.message,
    statusCode: error.response?.status,
  }

  return Promise.reject(customError)
}

export const addErrorInterceptor = (axiosInstance: AxiosInstance) =>
  axiosInstance.interceptors.response.use(
    (response) => response,
    errorInterceptor
  )
