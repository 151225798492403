import { DownloadOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons"
import { DeleteButton, useTable } from "@refinedev/antd"
import { useCan, useCreate, usePermissions } from "@refinedev/core"
import { Button, Card, Row, Space, Table } from "antd"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import { Order } from "../../entities/Order"
import { PurchaseQuotation } from "../../entities/PurchaseQuotation"
import { translatePurchaseQuotationStatus } from "../../helpers/PurchaseQuotationStatus"
import { downloadPurchaseQuotationsReport } from "../../service/reports"
import { ActionButton } from "../../helpers/buttons"

type Props = {
  order?: Order
  disabled?: boolean
}

const resource = "purchaseQuotations"

export const PurchaseQuotationPanel: React.FC<Props> = ({
  order,
  disabled,
}) => {
  const navigate = useNavigate()

  const { tableProps } = useTable({
    resource,
    filters: {
      permanent: [{ field: "orderId", operator: "eq", value: order?.id }],
    },
    sorters: {
      initial: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
  })

  const { mutate: create } = useCreate<PurchaseQuotation>()

  const handleCreate = () => {
    create({
      resource,
      values: { orderId: order?.id },
      successNotification: (values: any) => {
        const data = values["data"] as PurchaseQuotation[]

        if (Array.isArray(data) && !data.length) {
          return {
            message: "Todas as cotações já foram criadas",
            description: "",
            type: "error",
          }
        }

        return {
          message: "Cotações criadas com sucesso",
          description: "",
          type: "success",
        }
      },
    })
  }

  const handleShow = (pq: PurchaseQuotation) => {
    navigate(`/purchaseQuotations/show/${pq.id}`)
  }

  const handleDownload = (pq: PurchaseQuotation) => {
    downloadPurchaseQuotationsReport(pq.id)
  }

  return (
    <Card
      title={"Cotações"}
      size='small'
      style={{ height: "calc(100%)" }}
      extra={
        <Row>
          <Button
            icon={<PlusOutlined />}
            type='primary'
            onClick={handleCreate}
            resource={resource}
          >
            Gerar Cotações
          </Button>
        </Row>
      }
    >
      <Table {...tableProps} rowKey='id'>
        <Table.Column dataIndex='id' title='Id' sorter />
        <Table.Column
          dataIndex={["vendor", "companyName"]}
          title='Fornecedor'
          sorter
        />
        <Table.Column
          dataIndex={"totalPrice"}
          title='Valor total'
          sorter
          align='right'
          render={(value) => (value || 0).toFixed(2)}
        />
        <Table.Column
          dataIndex={"status"}
          title='Status'
          sorter
          render={(value) => translatePurchaseQuotationStatus(value)}
        />
        <Table.Column
          dataIndex={"updatedAt"}
          title='Atualizado em'
          sorter
          render={(value) =>
            dayjs(value).format("DD/MM/YYYY HH:mm").toUpperCase()
          }
        />
        <Table.Column
          title='Ações'
          dataIndex='actions'
          width='5rem'
          render={(_, record: PurchaseQuotation) => (
            <Space>
              <ActionButton
                icon={<EyeOutlined />}
                size='small'
                resource={resource}
                action='show'
                onClick={() => handleShow(record)}
              />
              <DeleteButton
                size='small'
                hideText
                resource={resource}
                recordItemId={record.id}
              />
              <ActionButton
                icon={<DownloadOutlined />}
                size='small'
                resource={resource}
                action='download'
                onClick={() => handleDownload(record)}
              />
            </Space>
          )}
        />
      </Table>
    </Card>
  )
}
