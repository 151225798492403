import { Create, useForm } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import React from "react";
import { UsersForm } from "./UsersForm";

export const UsersCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <UsersForm formProps={formProps} showPasswordFields suggestPassword />
    </Create>
  );
};
