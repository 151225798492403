import { Create, useForm } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import React from "react";
import { Product } from "../../entities/Product";
import { ProductsForm } from "./ProductsForm";

export const ProductsCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading } = useForm<any, any, Product>();

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <ProductsForm formProps={formProps} />
    </Create>
  );
};
