import React, { ReactNode, useEffect, useMemo, useRef } from "react"
import { Accessory } from "../../entities/Accessory"
import { useEditingTable } from "../../helpers/hooks"
import Table, { ColumnsType } from "antd/es/table"
import { Card, Modal, Select } from "antd"

type BaseEntity = {
  id: number
  accessory: Accessory
}

type ItemsTableProps<Entity extends BaseEntity> = {
  value?: Entity[]
  onChange?: (value: Entity[]) => void
  options?: Accessory[]
  template: Entity
  onValidateItem: (entity: Entity) => boolean
  valueColumnTitle?: string
  renderValueColumnShow?: (record: Entity) => ReactNode
  renderValueColumnEdit?: (
    record: Entity,
    onChange: (record: Partial<Entity>) => void
  ) => ReactNode
  canEdit?: boolean
  disabled?: boolean
}

export const AccessoriesTable = <T extends BaseEntity>({
  value: items,
  onChange,
  options = [],
  template,
  onValidateItem,
  valueColumnTitle,
  renderValueColumnEdit,
  renderValueColumnShow,
  canEdit = true,
  disabled = false,
}: ItemsTableProps<T>) => {
  const previousCount = useRef<number>(0)

  const accessoriesOptions = useMemo(() => {
    const usedAccessoriesIds = items?.map((item) => item.accessory.id)
    const notUsedAccessories = (options || []).filter(
      (o) => !usedAccessoriesIds?.includes(o.id)
    )
    return (
      notUsedAccessories.map((accessory) => ({
        value: accessory.id,
        label: accessory.name,
      })) || []
    )
  }, [items, options])

  const cloneAndDo = (action: (clone: T[]) => any) => {
    const itemsClone = structuredClone(items || [])
    const res = action(itemsClone)
    onChange && onChange(itemsClone)

    return res
  }

  const handleScroll = () => {
    const element = document.querySelector(".ant-table-body tr:last-child")
    element?.scrollIntoView()
  }

  useEffect(() => {
    if (items?.length && items?.length > previousCount.current) {
      handleScroll()
      previousCount.current = items?.length
    }
  }, [items?.length])

  const {
    isEditing,
    editingItem,
    updateEditingItem,
    renderTableButtons,
    renderRowButtons,
  } = useEditingTable<T>({
    disabled,
    onAddItem: () => {
      if (!accessoriesOptions.length) {
        const content = items?.length
          ? "Todos os acessórios deste produto já foram incluidos"
          : "O produto selecionado não possui acessórios"

        Modal.error({
          title: "Não há acessório disponíveis",
          content,
        })
        return {}
      }

      return cloneAndDo((itemsClone) => {
        itemsClone.push(Object.assign({}, template))

        return {
          index: itemsClone.length - 1,
          item: itemsClone[itemsClone.length - 1],
        }
      })
    },
    onUpdateItem: (item: T, index: number) => {
      cloneAndDo((itemsClone) => {
        itemsClone[index] = item
      })
    },
    onDeleteItem: (index: number) => {
      cloneAndDo((itemsClone) => {
        itemsClone.splice(index, 1)
      })
    },
    onValidateItem,
  })

  const itemsColumns: ColumnsType<T> = [
    {
      title: "Acessório",
      dataIndex: ["accessory", "name"],
      key: "accessory",
      render: (value, record, index) => {
        if (!isEditing(index) && !!record.accessory) {
          return value
        }

        return (
          <Select<any, any>
            style={{ width: "100%" }}
            value={editingItem?.accessory.id}
            onSelect={(value) => {
              const { id, name } = options.find((c) => c.id === value) || {}

              return updateEditingItem({
                accessory: {
                  id,
                  name,
                },
              } as Partial<T>)
            }}
            options={accessoriesOptions}
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input.toUpperCase())
            }
            showSearch
          />
        )
      },
    },
    {
      title: valueColumnTitle,
      key: "valueColumn",
      width: "10em",
      render: (value, record, index) => {
        if (!isEditing(index)) {
          return renderValueColumnShow && renderValueColumnShow(record)
        }

        return (
          renderValueColumnEdit &&
          renderValueColumnEdit(editingItem!, (record) =>
            updateEditingItem(record)
          )
        )
      },
    },
    {
      title: "Ações",
      key: "actions",
      width: "7em",
      align: "right" as const,
      render: renderRowButtons(canEdit),
    },
  ].filter((c) => !!c.title)

  return (
    <Card size='small' extra={renderTableButtons()} title='Lista de acessórios'>
      <Table
        dataSource={items}
        columns={itemsColumns}
        size='small'
        pagination={false}
        scroll={{ y: 350 }}
      />
    </Card>
  )
}

export const AccessoriesTableValidator = async <Entity extends BaseEntity>(
  rule,
  value: Entity[]
) => {
  let errors: string[] = []

  if (!value || !value.length) {
    errors.push("Nenhum item informado")
  }

  const findDuplicates = (res, id, index, arr) =>
    res || arr.indexOf(id) !== index

  const hasDuplicatedComponents = value
    .map((pa) => pa.accessory?.id)
    .filter(Boolean)
    .reduce(findDuplicates, false)

  if (hasDuplicatedComponents) {
    errors.push("Há acessórios duplicados")
  }

  const hasEmptyComponents =
    value.map((pqi) => Boolean(pqi.accessory)).filter((c) => !c).length > 0

  if (hasEmptyComponents) {
    errors.push("Há acessórios não informados")
  }

  if (errors.length) return Promise.reject(errors)
}
