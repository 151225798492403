import { ProductComponent } from "../../../entities/Product"

type Location = {
  part: string
  place: string
}

export class PCWrapper {
  private productComponents: Map<string, Map<string, ProductComponent[]>> =
    new Map()

  constructor(productsComponents?: ProductComponent[]) {
    const parts = productsComponents?.map((pc) => pc.part)

    // Insert parts
    parts?.forEach(
      (part) =>
        this.productComponents.has(part) ||
        this.productComponents.set(part, new Map())
    )

    // Insert places inside parts
    for (const [part, place] of this.productComponents.entries()) {
      const components = productsComponents?.filter((pc) => pc.part === part)

      components?.forEach((c) => place.has(c.place) || place.set(c.place, []))
    }

    // Insert productComponentes inside places
    for (const [part, place] of this.productComponents.entries()) {
      for (const [sPlace] of place) {
        const components = (productsComponents || []).filter(
          (pc) => pc.part === part && pc.place === sPlace
        )
        place.get(sPlace)?.push(...components)
      }
    }

    console.dir(this.productComponents)
  }

  keyFromProductComponent({ part, place }: Location) {
    return `${part}::${place}`
  }

  splitKey(key: string): Location {
    const [part, place] = key.split("::")
    return { part, place }
  }

  keyIsPartOnly(key: string) {
    const { place } = this.splitKey(key)
    return !place
  }

  productComponentsFromKey(key: string) {
    const { part, place } = this.splitKey(key)

    return this.productComponents.get(part)?.get(place) || []
  }

  remove({ part, place }: Location) {
    place
      ? this.productComponents.get(part)?.delete(place)
      : this.productComponents.delete(part)

    return this.toArray()
  }

  componentsInside({ part, place }: Location) {
    return this.productComponents.get(part)?.get(place) || []
  }

  nextId() {
    return (
      this.toArray().reduce((acc, pc) => (pc.id < acc ? pc.id : acc), 0) - 1
    )
  }

  addNewPart() {
    const newPartName = `NOVA PARTE ${this.tag()}`
    const newPlaceName = `NOVO LOCAL ${this.tag()}`

    const data = {
      id: this.nextId(),
      part: newPartName,
      place: newPlaceName,
    } as ProductComponent

    this.productComponents.set(newPartName, new Map().set(newPlaceName, [data]))

    return this.toArray()
  }

  tag() {
    return Math.random().toString(36).slice(-4)
  }

  insertAtEndOfPart(key: string, data: Partial<ProductComponent>) {
    const { part } = this.splitKey(key)

    const components = this.productComponents.get(part)?.get(data.place!) || []

    this.productComponents
      .get(part)
      ?.set(data.place!, [...components, data as ProductComponent])

    return this.toArray()
  }

  addNewPlace(key: string) {
    const { part } = this.splitKey(key)

    const newPlaceName = `NOVO LOCAL ${this.tag()}`
    const data = { id: this.nextId(), part, place: newPlaceName }

    return this.insertAtEndOfPart(key, data)
  }

  addNewProduct(key: string) {
    return this.insertAtEndOfPart(key, {
      id: this.nextId(),
      ...this.splitKey(key),
      quantity: 1,
    })
  }

  update(key: string, newValue: string) {
    const { part, place } = this.splitKey(key)
    const isEditingPlace = !!place

    const newComponents = this.toArray().map((pc) => {
      const match = isEditingPlace
        ? pc.part === part && pc.place === place
        : pc.part === part

      if (!match) {
        return pc
      }

      if (isEditingPlace) {
        return {
          ...pc,
          place: newValue,
        }
      }

      return {
        ...pc,
        part: newValue,
      }
    })

    return newComponents
  }

  groupComponentsByPart(key: string) {
    const { part } = this.splitKey(key)

    return this.toArray()
      .filter((pc) => pc.part === part)
      .reduce((acc, current) => {
        const foundItem = acc.find(
          (item) => item.component?.id === current.component?.id
        )
        if (foundItem) {
          foundItem.quantity += current.quantity
        } else {
          acc.push(current)
        }

        return acc
      }, [] as ProductComponent[])
      .sort((a, z) => {
        const byDescription =
          a.component &&
          a.component.description &&
          z.component &&
          z.component.description
            ? a.component.description
                .trim()
                .localeCompare(z.component.description.trim())
            : 0

        const byQuantity = z.quantity - a.quantity

        return byDescription || byQuantity
      }) as ProductComponent[]
  }

  toArray() {
    const res: ProductComponent[] = []

    for (const [part, place] of this.productComponents.entries()) {
      for (const [sPlace, components] of place) {
        res.push(...components)
      }
    }

    return structuredClone(res)
  }

  //seleciona o material e a descrição não é setada
  validate() {
    const findDuplicates = (id, index, arr) => arr.indexOf(id) !== index
    const makeSample = (list: (string | undefined)[]) =>
      list.find(Boolean)?.split("|").slice(0, 2).join(" / ")

    const data = this.toArray()

    const duplicatedComponents = data
      .map((pc) =>
        pc.component?.id
          ? `${pc.part}|${pc.place}|${pc.component.id}`
          : undefined
      )
      .filter(Boolean)
      .filter(findDuplicates)

    if (duplicatedComponents && duplicatedComponents.length) {
      const sample = makeSample(duplicatedComponents)
      return [`Há componentes duplicados em ${sample}`]
    }

    const emptyComponents = data
      .map((pc) => `${pc.part}|${pc.place}|${pc.component?.id || ""}`)
      .filter((c) => {
        const [place, part, component] = c?.split("|")
        return !component
      })

    if (emptyComponents && emptyComponents.length) {
      const sample = makeSample(emptyComponents)
      return [`Há componentes não informados em ${sample}`]
    }

    const emptyQuantities = data
      .map((pc) => `${pc.part}|${pc.place}|${pc.quantity || "0"}`)
      .filter((c) => {
        const [place, part, quantity] = c?.split("|")
        return quantity === "0"
      })

    if (emptyQuantities && emptyQuantities.length) {
      const sample = makeSample(emptyQuantities)
      return [`Há quantidades não informadas em ${sample}`]
    }
  }
}
