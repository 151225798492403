import { useSelect } from "@refinedev/antd"
import {
  Card,
  Form,
  FormProps,
  Input,
  Select,
  Space,
  Switch,
  Typography,
} from "antd"
import React from "react"
import { Component, ComponentItem } from "../../entities/Component"
import { ComponentGroup } from "../../entities/ComponentGroup"
import { ComponentsTable } from "../../helpers/ComponentsTable"
import { AuditPanel } from "../audit-panel/AuditPanel"
import { RowSpacer } from "../row-spacer/RowSpacer"

type Props = {
  formProps: FormProps<Component>
  disabled?: boolean
}

export const ComponentsForm: React.FC<Props> = ({ formProps, disabled }) => {
  const { selectProps: componentsGroupsSelectProps } =
    useSelect<ComponentGroup>({
      resource: "componentsGroups",
      optionValue: "id",
      optionLabel: "name",
      onSearch: (search: string) => [
        {
          field: "search",
          operator: "contains",
          value: search,
        },
      ],
    })

  return (
    <Form {...formProps} layout='vertical' disabled={disabled}>
      <RowSpacer spans={[4, 12, 8]}>
        <Form.Item label='Id' name={["id"]}>
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label='Descrição'
          name={["description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input autoFocus style={{ textTransform: "uppercase" }} />
        </Form.Item>
        <Form.Item label='Grupo de Materiais' name={["group", "id"]}>
          <Select
            placeholder='Selecione um grupo'
            {...componentsGroupsSelectProps}
            allowClear
          />
        </Form.Item>
        <Form.Item>
          <Card size='small' title='Kit de materiais'>
            <Space size='large'>
              <Form.Item name='kit' valuePropName='checked'>
                <Switch />
              </Form.Item>
              <Typography.Paragraph>
                Se ativado, indica que este material representa um kit de
                materiais. A descrição será usada como identificação para os
                cadastros. Porém, em relatórios, os materiais que o compõe serão
                exibidos.
              </Typography.Paragraph>
            </Space>
          </Card>
        </Form.Item>
        <Form.Item<TableItem>
          name='items'
          rules={[{ validator: componentsTableValidator }]}
        >
          <TableWrapper id={formProps.initialValues?.id} />
        </Form.Item>
      </RowSpacer>
      <RowSpacer>
        {formProps.initialValues && (
          <AuditPanel tableName='components' pk={formProps.initialValues.id} />
        )}
      </RowSpacer>
    </Form>
  )
}

type TableItem = ComponentItem & {
  component: Component
}

type TableWrapperProps = {
  value?: ComponentItem[]
  onChange?: (value: ComponentItem) => void
  id: number
}

const TableWrapper: React.FC<TableWrapperProps> = ({ value, onChange, id }) => {
  const from = (value?: ComponentItem[]): TableItem[] =>
    (value || []).map((value) => ({
      ...value,
      component: value.item,
      item: undefined,
    })) as any

  const to = (values?: TableItem[]): ComponentItem =>
    (values || []).map((value) => ({
      ...value,
      component: undefined,
      item: value.component,
    })) as any

  const handleOnChange = (values?: TableItem[]) => {
    onChange && onChange(to(values))
  }

  return (
    <ComponentsTable
      value={from(value)}
      onChange={handleOnChange}
      title='Items do Kit'
      hideKits
      excludeId={id}
    />
  )
}

//TODO: how to get away with this copy?
export const componentsTableValidator = async (
  rule,
  value?: ComponentItem[]
) => {
  let errors: string[] = []

  const findDuplicates = (res, id, index, arr) =>
    res || arr.indexOf(id) !== index

  const hasDuplicatedComponents = (value || [])
    .map((i) => i.item?.id)
    .filter(Boolean)
    .reduce(findDuplicates, false)

  if (hasDuplicatedComponents) {
    errors.push("Há materiais duplicados")
  }

  const hasEmptyComponents =
    (value || []).map((i) => Boolean(i.item)).filter((c) => !c).length > 0

  if (hasEmptyComponents) {
    errors.push("Há materiais não informados")
  }

  const hasEmptyQuantities = (value || [])
    .map((i) => !i.quantity)
    .reduce((acc, cur) => acc || cur, false)

  if (hasEmptyQuantities) {
    errors.push("Há quantidades não informadas")
  }

  if (errors.length) return Promise.reject(errors)
}
