import { AxiosResponse } from "axios"
import { PurchaseQuotation } from "../../entities/PurchaseQuotation"
import { axiosInstance } from "../axiosInstance"

type AdvancePurchaseQuotationReturn =
  | PurchaseQuotation
  | {
      code: string
      msg: string
    }
export const advancePurchaseQuotation = (
  purchaseQuotation: PurchaseQuotation
): Promise<AxiosResponse<AdvancePurchaseQuotationReturn>> =>
  axiosInstance.post<AdvancePurchaseQuotationReturn>(
    `/purchaseQuotations/advance/${purchaseQuotation.id}`
  )
