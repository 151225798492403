import { Edit, useForm } from "@refinedev/antd"
import { IResourceComponentsProps } from "@refinedev/core"
import React from "react"
import { Vendor } from "../../../entities/Vendor"
import { PartnersForm } from "../components/PartnersForm"

export const VendorsEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading, queryResult } = useForm<
    Vendor,
    any,
    Vendor
  >()

  return (
    <Edit saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <PartnersForm<Vendor>
        formProps={formProps}
        entity={queryResult?.data?.data}
        auditTableName='vendors'
      />
    </Edit>
  )
}
