import { Col, Row } from "antd"
import { useWatch } from "antd/es/form/Form"
import { useEffect, useMemo, useState } from "react"
import { ProductComponent } from "../../../entities/Product"
import { SummaryComponents } from "./ComponentsSummary"
import { ComponentsTable } from "./ComponentsTable"
import { LocationTree } from "./LocationTree"
import { PCWrapper } from "./pcwrapper"

type Props = {
  value?: ProductComponent[]
  onChange?: any
  disabled?: boolean
}

export const ComponentsInnerForm: React.FC<Props> = ({
  value,
  onChange,
  disabled,
}) => {
  const [selectedKey, setSelectedKey] = useState("")
  const wrapper = useMemo(() => new PCWrapper(value), [value])

  const handleSelectKey = (key: string) => setSelectedKey(key)

  const isPartOnly = wrapper.keyIsPartOnly(selectedKey)

  /**** */
  const pc = useWatch<ProductComponent[]>("components")

  useEffect(() => {
    console.table(
      pc?.map((p) => ({
        id: p.id,
        part: p.part,
        place: p.place,
        c_id: p.component?.id,
        c_desc: p.component?.description,
        quantity: p.quantity,
      }))
    )
  }, [pc])
  /**** */

  return (
    <Row>
      <Col md={10}>
        <LocationTree
          wrapper={wrapper}
          onChange={onChange}
          selectedKey={selectedKey}
          onSelectKey={handleSelectKey}
        />
      </Col>
      <Col md={14}>
        <div style={{ display: !isPartOnly ? "none" : "inherit" }}>
          <SummaryComponents
            wrapper={wrapper}
            onChange={onChange}
            selectedKey={selectedKey}
          />
        </div>
        <div style={{ display: isPartOnly ? "none" : "inherit" }}>
          <ComponentsTable
            wrapper={wrapper}
            onChange={onChange}
            selectedKey={selectedKey}
            disabled={disabled}
          />
        </div>
      </Col>
    </Row>
  )
}
