import { Form } from "antd"
import useFormInstance from "antd/es/form/hooks/useFormInstance"
import { Accessory } from "../../entities/Accessory"
import { Product, ProductAccessory } from "../../entities/Product"
import {
  AccessoriesTable,
  AccessoriesTableValidator,
} from "../accessories-table/AccessoriesTable"

export const AccessoriesForm: React.FC = () => {
  const form = useFormInstance()
  const product = form.getFieldValue("product")

  return (
    <Form.Item
      id='accessories'
      name={"accessories"}
      rules={[
        {
          validator: (rule, values) =>
            AccessoriesTableValidator(rule, wrap(values)),
        },
      ]}
    >
      <AccessoriesTableFormItem product={product} />
    </Form.Item>
  )
}

type WrappedAccessory = {
  id: number
  accessory: Accessory
}

const wrap = (accessories?: Accessory[]) =>
  (accessories || []).map<WrappedAccessory>((accessory, index) => ({
    id: index,
    accessory,
  }))

const unwrap = (wrappedAccessories?: WrappedAccessory[]) =>
  (wrappedAccessories || []).map((wa) => wa.accessory)

type ItemsTableProps = {
  product: Product
  value?: Accessory[]
  onChange?: (value: Accessory[]) => void
}

const AccessoriesTableFormItem: React.FC<ItemsTableProps> = ({
  product,
  value,
  onChange,
}) => {
  const accessoriesOptions = product?.accessories.map((pa) => pa.accessory)

  return (
    <AccessoriesTable
      value={wrap(value)}
      onChange={(value) => onChange && onChange(unwrap(value))}
      options={accessoriesOptions}
      template={
        { accessory: {} as Accessory, included: true } as ProductAccessory
      }
      onValidateItem={(item) => {
        return !!item.accessory?.id
      }}
      canEdit={false}
    />
  )
}
