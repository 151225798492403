import { CanParams } from "@refinedev/core"
import { getUserFromToken } from "./authProvider"
import { Permission } from "./entities/Permission"

export const accessControlProvider = {
  can: async ({ resource, action, params }: CanParams) => {
    const user = getUserFromToken()
    const permissions = user?.role.permissions || []
    const normalizedResource = resource ? resource.toLowerCase() : undefined

    const editSelfUser =
      resource === "users" &&
      action === "edit" &&
      params?.id?.toString() === user?.id.toString()

    const blockAuditForUsers =
      normalizedResource === "audit" && user?.username !== "admin"

    if (blockAuditForUsers) {
      return { can: false }
    }

    const isMenu = Boolean(resource?.startsWith("menu:"))

    const can =
      permissions.some((p: Permission) => p.name === "*:*") ||
      permissions.some(
        (p: Permission) => p.name === `${normalizedResource}:*`
      ) ||
      permissions.some(
        (p: Permission) => p.name === `${normalizedResource}:${action}`
      ) ||
      isMenu ||
      editSelfUser

    return { can }
  },
}
