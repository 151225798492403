import React from "react"
import { IResourceComponentsProps, BaseRecord } from "@refinedev/core"
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  BooleanField,
  CreateButton,
} from "@refinedev/antd"
import { Table, Space } from "antd"
import { onSearch, SearchForm } from "../search-form/SearchForm"

export const UsersList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable({
    syncWithLocation: true,
    onSearch,
  })

  return (
    <List
      headerButtons={[<SearchForm {...searchFormProps} />, <CreateButton />]}
    >
      <Table {...tableProps} rowKey='id'>
        <Table.Column dataIndex='id' title='Id' />
        <Table.Column dataIndex='username' title='Usuário' />
        <Table.Column dataIndex='firstName' title='Nome' />
        <Table.Column dataIndex='lastName' title='Sobrenome' />
        <Table.Column
          dataIndex={["isActive"]}
          title='Ativo'
          render={(value: any) => <BooleanField value={value} />}
        />
        <Table.Column
          title='Ações'
          dataIndex='actions'
          width='10rem'
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size='small' recordItemId={record.id} />
              <ShowButton hideText size='small' recordItemId={record.id} />
              <DeleteButton hideText size='small' recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
