import { Edit, useForm } from "@refinedev/antd"
import { IResourceComponentsProps } from "@refinedev/core"
import React from "react"
import { Component } from "../../entities/Component"
import { ComponentsForm } from "./ComponentsForm"

export const ComponentsEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading } = useForm<
    any,
    any,
    Component
  >()

  return (
    <Edit saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <ComponentsForm formProps={formProps} />
    </Edit>
  )
}
