import { Edit, useForm } from "@refinedev/antd"
import { IResourceComponentsProps } from "@refinedev/core"
import React from "react"
import { PartnersForm } from "../components/PartnersForm"
import { Customer } from "../../../entities/Customer"

export const CustomersEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading, queryResult } = useForm<
    Customer,
    any,
    Customer
  >()

  return (
    <Edit saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <PartnersForm<Customer>
        formProps={formProps}
        entity={queryResult?.data?.data}
        auditTableName='customers'
      />
    </Edit>
  )
}
