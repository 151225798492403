import { CheckOutlined } from "@ant-design/icons"
import {
  CreateButton,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd"
import { BaseRecord, IResourceComponentsProps } from "@refinedev/core"
import { Space, Table } from "antd"
import React from "react"
import { SearchForm, onSearch } from "../search-form/SearchForm"

export const ComponentsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable({
    syncWithLocation: true,
    onSearch,
  })

  return (
    <List
      headerButtons={[<SearchForm {...searchFormProps} />, <CreateButton />]}
    >
      <Table {...tableProps} rowKey='id'>
        <Table.Column dataIndex='id' title='Id' sorter />
        <Table.Column dataIndex='description' title='Descrição' sorter />
        <Table.Column dataIndex={["group", "name"]} title='Grupo' />
        <Table.Column
          dataIndex='kit'
          title='Kit'
          render={(value) => (value ? <CheckOutlined /> : undefined)}
        />
        <Table.Column
          title='Actions'
          dataIndex='actions'
          width='10rem'
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size='small' recordItemId={record.id} />
              <ShowButton hideText size='small' recordItemId={record.id} />
              <DeleteButton hideText size='small' recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
