import { QuestionCircleFilled, RedoOutlined } from "@ant-design/icons"
import { useSelect } from "@refinedev/antd"
import { useCan } from "@refinedev/core"
import {
  Button,
  Checkbox,
  Col,
  Form,
  FormProps,
  Input,
  Row,
  Select,
  Tooltip,
} from "antd"
import { useWatch } from "antd/es/form/Form"
import useFormInstance from "antd/es/form/hooks/useFormInstance"
import { useCallback, useEffect, useState } from "react"
import { Role } from "../../entities/Role"
import { generatePassword } from "../../helpers/generatePassword"
import { usersUsingUsername } from "../../service/users"
import { DividerLine } from "../divider-line/DividerLine"
import { RowSpacer } from "../row-spacer/RowSpacer"
import { AuditPanel } from "../audit-panel/AuditPanel"

type Props = {
  formProps: FormProps
  disabled?: boolean
  isMe?: boolean
  showPasswordFields?: boolean
  suggestPassword?: boolean
}

export const UsersForm: React.FC<Props> = ({
  formProps,
  disabled,
  isMe,
  showPasswordFields,
  suggestPassword = false,
}) => {
  const { selectProps } = useSelect<Role>({
    resource: "roles",
    optionValue: "id",
    optionLabel: "name",
  })

  const { data: updatePasswordPermission } = useCan({
    resource: "users",
    action: "update-password",
  })
  const { data: canEditUser } = useCan({ resource: "users", action: "edit" })

  const id = formProps.initialValues?.id

  const userNameValidator = useCallback(
    async (rule, value: string) => {
      if (!value) return

      const valid = value.match(
        /^(?=[a-zA-Z0-9._]{5,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/
      )

      if (!valid) return Promise.reject(<InvalidUsername />)

      try {
        const { data } = await usersUsingUsername(value, id)

        if (data.length) {
          return Promise.reject(`Nome de usuário já está em uso`)
        }
      } catch (e) {
        console.error(e)
        return Promise.reject("Erro ao validar o nome de usuário")
      }
    },
    [id]
  )

  return (
    <Form
      {...formProps}
      layout='vertical'
      disabled={disabled}
      initialValues={{ ...formProps.initialValues, isActive: true }}
    >
      <Row gutter={24}>
        <Col xs={24} sm={4}>
          <Form.Item label='Id' name={["id"]}>
            <Input readOnly disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={20}>
          <Form.Item
            label='Usuário'
            name={["username"]}
            rules={[
              {
                required: true,
              },
              {
                validator: userNameValidator,
              },
            ]}
          >
            <Input
              disabled={!canEditUser?.can}
              style={{ textTransform: "lowercase" }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={12}>
          <Form.Item
            label='Nome'
            name={["firstName"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              disabled={!canEditUser?.can}
              style={{ textTransform: "uppercase" }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item label='Sobrenome' name={["lastName"]}>
            <Input
              disabled={!canEditUser?.can}
              style={{ textTransform: "uppercase" }}
            />
          </Form.Item>
        </Col>
      </Row>
      {showPasswordFields && (isMe || updatePasswordPermission?.can) && (
        <>
          <ChangePasswordForm isMe={isMe} suggestPassword={suggestPassword} />
        </>
      )}
      <DividerLine title='Permissões' />
      <Row gutter={24}>
        <Col>
          <Form.Item
            label='Papel'
            name={["role", "id"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder='Selecione uma categoria'
              style={{ width: 300 }}
              {...selectProps}
              disabled={!canEditUser?.can}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label='Ativo' valuePropName='checked' name={["isActive"]}>
            <Checkbox disabled={!canEditUser?.can}>Ativo</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <RowSpacer>
        {formProps.initialValues && (
          <AuditPanel tableName='users' pk={formProps.initialValues.id} />
        )}
      </RowSpacer>
    </Form>
  )
}

type ChangePasswordFormProps = {
  isMe?: boolean
  suggestPassword?: boolean
}

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  isMe,
  suggestPassword,
}) => {
  const form = useFormInstance()
  const [changingPassword, setChangingPassword] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)

  const currentPassword = useWatch<string>([
    "changePassword",
    "currentPassword",
  ])
  const newPassword = useWatch<string>(["changePassword", "newPassword"])
  const confirmPassword = useWatch<string>([
    "changePassword",
    "confirmPassword",
  ])

  const generateAndFillPassword = useCallback(() => {
    const password = generatePassword(6)

    setPasswordVisible(true)
    form.setFieldValue(["changePassword", "newPassword"], password)
    form.setFieldValue(["changePassword", "confirmPassword"], password)
  }, [form])

  useEffect(() => {
    if (!suggestPassword) return

    generateAndFillPassword()
  }, [generateAndFillPassword, suggestPassword])

  useEffect(() => {
    setChangingPassword(!!currentPassword || !!newPassword || !!confirmPassword)
  }, [currentPassword, newPassword, confirmPassword])

  const validateRequirements = (rule, value: string, callback) => {
    /**
     * Password must contain one digit from 1 to 9,
     * one lowercase letter,
     * one uppercase letter,
     * one special character,
     * no space,
     * and it must be 8-16 characters long.
     * */
    const requirements =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{6,16}$/

    if (value && !value.match(requirements)) {
      callback(<WeakPassword />)
    }
    callback()
  }

  const matchNewPassword = useCallback(
    (rule, value: string, callback) => {
      if (value && newPassword && value !== newPassword) {
        callback("As senhas não conferem")
      }
      callback()
    },
    [newPassword]
  )

  return (
    <>
      <DividerLine title='Credenciais' />
      <RowSpacer spans={[6, 6, 6, 2]}>
        {isMe && (
          <Form.Item
            label='Senha atual'
            name={["changePassword", "currentPassword"]}
            rules={[{ required: changingPassword }]}
          >
            <Input.Password />
          </Form.Item>
        )}
        <Form.Item
          label='Nova senha'
          name={["changePassword", "newPassword"]}
          rules={[
            { required: changingPassword },
            { validator: validateRequirements },
          ]}
        >
          <Input.Password
            visibilityToggle={{
              visible: passwordVisible,
              onVisibleChange: setPasswordVisible,
            }}
          />
        </Form.Item>
        <Form.Item
          label='Confirmar senha'
          name={["changePassword", "confirmPassword"]}
          rules={[
            { required: changingPassword },
            { validator: matchNewPassword },
          ]}
        >
          <Input.Password
            visibilityToggle={{
              visible: passwordVisible,
              onVisibleChange: setPasswordVisible,
            }}
          />
        </Form.Item>
        <Form.Item label=' '>
          <Tooltip title='Gera novas credenciais'>
            <Button onClick={generateAndFillPassword}>
              <RedoOutlined />
            </Button>
          </Tooltip>
        </Form.Item>
      </RowSpacer>
    </>
  )
}

const WeakPassword: React.FC = () => {
  const title = (
    <>
      A senha deve conter:
      <ul>
        <li>entre 8 e 16 caracteres</li>
        <li>uma letra maiúscula</li>
        <li>uma letra minúscula</li>
        <li>um caractere especial</li>
      </ul>
    </>
  )

  return (
    <>
      Senha fraca{" "}
      <Tooltip title={title} placement={"bottom"}>
        <QuestionCircleFilled />
      </Tooltip>
    </>
  )
}

const InvalidUsername: React.FC = () => {
  const title = (
    <>
      O nome de usuário:
      <ul>
        <li>deve ter entre 6 e 20 caracteres</li>
        <li>pode conter letras maísculas, minúsculas e números</li>
        <li>não pode conter caracteres especiais e espaços</li>
      </ul>
    </>
  )

  return (
    <>
      Nome de usuário inválido{" "}
      <Tooltip title={title} placement={"bottom"}>
        <QuestionCircleFilled />
      </Tooltip>
    </>
  )
}
