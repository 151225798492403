import {
  AppstoreOutlined,
  EyeOutlined,
  FileTextOutlined,
  GroupOutlined,
  MenuOutlined,
  RightCircleOutlined,
  RightOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
  ToolOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons"
import { AntdInferencer } from "@refinedev/inferencer/antd"
import { AccessoriesCreate } from "./components/accessories/AccessoriesCreate"
import { AccessoriesEdit } from "./components/accessories/AccessoriesEdit"
import { AccessoriesList } from "./components/accessories/AccessoriesList"
import { AccessoriesShow } from "./components/accessories/AccessoriesShow"
import { AuditLogList } from "./components/audit-log/AuditLogList"
import { AuditLogShow } from "./components/audit-log/AuditLogShow"
import { ComponentsGroupsCreate } from "./components/components-groups/ComponentsGroupsCreate"
import { ComponentsGroupsEdit } from "./components/components-groups/ComponentsGroupsEdit"
import { ComponentsGroupsList } from "./components/components-groups/ComponentsGroupsList"
import { ComponentsGroupsShow } from "./components/components-groups/ComponentsGroupsShow"
import { ComponentsCreate } from "./components/components/ComponentsCreate"
import { ComponentsEdit } from "./components/components/ComponentsEdit"
import { ComponentsList } from "./components/components/ComponentsList"
import { ComponentsShow } from "./components/components/ComponentsShow"
import { OrdersStatusEdit } from "./components/orders-statuses/OrdersStatusesEdit"
import { OrdersStatusList } from "./components/orders-statuses/OrdersStatusesList"
import { OrdersStatusesShow } from "./components/orders-statuses/OrdersStatusesShow"
import { OrdersCreate } from "./components/orders/OrdersCreate"
import { OrdersEdit } from "./components/orders/OrdersEdit"
import { OrdersList } from "./components/orders/OrdersList"
import { OrdersShow } from "./components/orders/OrdersShow"
import { OrdersKanban } from "./components/orders/kanban/OrdersKanban"
import { CustomersCreate } from "./components/partners/customers/CustomersCreate"
import { CustomersEdit } from "./components/partners/customers/CustomersEdit"
import { CustomersList } from "./components/partners/customers/CustomersList"
import { CustomersShow } from "./components/partners/customers/CustomersShow"
import { VendorsCreate } from "./components/partners/vendors/VendorsCreate"
import { VendorsEdit } from "./components/partners/vendors/VendorsEdit"
import { VendorsList } from "./components/partners/vendors/VendorsList"
import { VendorsShow } from "./components/partners/vendors/VendorsShow"
import { ProductsCreate } from "./components/products/ProductsCreate"
import { ProductsEdit } from "./components/products/ProductsEdit"
import { ProductsList } from "./components/products/ProductsList"
import { ProductsShow } from "./components/products/ProductsShow"
import { PurchaseQuotationsEdit } from "./components/purchase-quotations/PurchaseQuotationsEdit"
import { PurchaseQuotationsList } from "./components/purchase-quotations/PurchaseQuotationsList"
import { PurchaseQuotationsShow } from "./components/purchase-quotations/PurchaseQuotationsShow"
import { UsersCreate } from "./components/users/UsersCreate"
import { UsersEdit } from "./components/users/UsersEdit"
import { UsersList } from "./components/users/UsersList"
import { UsersShow } from "./components/users/UsersShow"

type Resource = {
  name: string
  components: {
    list?: JSX.Element
    edit?: JSX.Element
    show?: JSX.Element
    create?: JSX.Element
  }
  icon?: JSX.Element
  meta?: Record<"parent", string>
}

export const resources: Resource[] = [
  {
    name: "kanban",
    components: {
      list: <OrdersKanban />,
    },
    icon: <EyeOutlined />,
  },

  /**
   * Production
   */
  {
    name: "orders",
    components: {
      list: <OrdersList />,
      edit: <OrdersEdit />,
      show: <OrdersShow />,
      create: <OrdersCreate />,
    },
    icon: <FileTextOutlined />,
    meta: { parent: "menu:sales" },
  },

  /**
   * Purchases
   */
  {
    name: "purchaseQuotations",
    components: {
      list: <PurchaseQuotationsList />,
      edit: <PurchaseQuotationsEdit />,
      show: <PurchaseQuotationsShow />,
    },
    icon: <ShoppingCartOutlined />,
    meta: { parent: "menu:purchases" },
  },

  /**
   * Partners
   */
  {
    name: "customers",
    components: {
      list: <CustomersList />,
      edit: <CustomersEdit />,
      show: <CustomersShow />,
      create: <CustomersCreate />,
    },
    icon: <UserOutlined />,
    meta: { parent: "menu:partners" },
  },
  {
    name: "vendors",
    components: {
      list: <VendorsList />,
      edit: <VendorsEdit />,
      show: <VendorsShow />,
      create: <VendorsCreate />,
    },
    icon: <ShopOutlined />,
    meta: { parent: "menu:partners" },
  },

  /**
   * Configuration
   */
  {
    name: "products",
    components: {
      list: <ProductsList />,
      edit: <ProductsEdit />,
      show: <ProductsShow />,
      create: <ProductsCreate />,
    },
    icon: <AppstoreOutlined />,
    meta: { parent: "menu:configuration" },
  },
  {
    name: "accessories",
    components: {
      list: <AccessoriesList />,
      edit: <AccessoriesEdit />,
      show: <AccessoriesShow />,
      create: <AccessoriesCreate />,
    },
    icon: <ToolOutlined />,
    meta: { parent: "menu:configuration" },
  },
  {
    name: "components",
    components: {
      list: <ComponentsList />,
      edit: <ComponentsEdit />,
      show: <ComponentsShow />,
      create: <ComponentsCreate />,
    },
    icon: <MenuOutlined />,
    meta: { parent: "menu:configuration" },
  },
  {
    name: "componentsGroups",
    components: {
      list: <ComponentsGroupsList />,
      edit: <ComponentsGroupsEdit />,
      show: <ComponentsGroupsShow />,
      create: <ComponentsGroupsCreate />,
    },
    icon: <GroupOutlined />,
    meta: { parent: "menu:configuration" },
  },
  {
    name: "ordersStatuses",
    components: {
      list: <OrdersStatusList />,
      edit: <OrdersStatusEdit />,
      show: <OrdersStatusesShow />,
      create: <AntdInferencer />,
    },
    icon: <RightCircleOutlined />,
    meta: { parent: "menu:configuration" },
  },
  {
    name: "productionStatuses",
    components: {
      list: <OrdersStatusList />, // HACKYYY!!!
      edit: <OrdersStatusEdit />, // HACKYYY!!!
      show: <OrdersStatusesShow />, // HACKYYY!!!
    },
    icon: <RightOutlined />,
    meta: { parent: "menu:configuration" },
  },

  /**
   * Access
   */
  {
    name: "users",
    components: {
      list: <UsersList />,
      edit: <UsersEdit />,
      show: <UsersShow />,
      create: <UsersCreate />,
    },
    icon: <UsergroupAddOutlined />,
    meta: { parent: "menu:access" },
  },

  /**
   * Root
   */
  {
    name: "audit",
    components: {
      list: <AuditLogList />,
      show: <AuditLogShow />,
    },
    icon: <SolutionOutlined />,
  },
]
