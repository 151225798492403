import {
  Edit,
  ListButton,
  RefreshButton,
  SaveButton,
  ShowButton,
  useForm,
} from "@refinedev/antd"
import { IResourceComponentsProps } from "@refinedev/core"
import React from "react"
import { Order } from "../../entities/Order"
import { OrdersForm } from "./OrdersForm"
import { RevertButton } from "../../helpers/buttons"

export const OrdersEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, form } = useForm<Order, any, Order>()

  return (
    <Edit
      headerButtons={[<ListButton />, <ShowButton />, <RefreshButton />]}
      footerButtons={[
        <RevertButton form={form} />,
        <SaveButton onClick={() => form.submit()} />,
      ]}
    >
      <OrdersForm formProps={formProps} />
    </Edit>
  )
}
