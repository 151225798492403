import { AutoComplete, Form, Input } from "antd"
import { MaskedInput } from "antd-mask-input"
import DisabledContext from "antd/es/config-provider/DisabledContext"
import { RowSpacer } from "components/row-spacer/RowSpacer"
import React, { useContext } from "react"

const AddressTypes = [
  "Rua",
  "Avenida",
  "Praça",
  "Alameda",
  "Travessa",
  "Largo",
  "Viela",
  "Beco",
  "Estrada",
  "Rodovia",
  "Boulevard",
  "Passarela",
].map((t) => ({
  value: t.slice(0, 1).toUpperCase() + t.slice(1),
}))

export const AddressForm: React.FC = () => {
  const disabled = useContext(DisabledContext)

  return (
    <>
      <RowSpacer spans={[4, 4, 12, 4, /**/ 4, 16, 4]}>
        <Form.Item label='CEP' name={["addressZipCode"]}>
          <MaskedInput mask='00000-000' disabled={disabled} />
        </Form.Item>

        <Form.Item label='Tipo' name={["addressType"]}>
          <AutoComplete options={AddressTypes} />
        </Form.Item>
        <Form.Item label='Logradouro' name={["addressStreet"]}>
          <Input style={{ textTransform: "uppercase" }} />
        </Form.Item>
        <Form.Item label='Número' name={["addressNumber"]}>
          <Input style={{ textTransform: "uppercase" }} />
        </Form.Item>
        <Form.Item label='Bairro' name={["addressNeighborhood"]}>
          <Input style={{ textTransform: "uppercase" }} />
        </Form.Item>
        <Form.Item label='Cidade' name={["addressCity"]}>
          <Input style={{ textTransform: "uppercase" }} />
        </Form.Item>
        <Form.Item label='UF' name={["addressState"]}>
          <Input style={{ textTransform: "uppercase" }} />
        </Form.Item>
      </RowSpacer>
    </>
  )
}
