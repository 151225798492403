import { ExclamationCircleFilled } from "@ant-design/icons"
import { FormInstance, Tooltip } from "antd"
import { useState } from "react"

export const useTabLabel = <T extends {}>(form?: FormInstance<T>) => {
  const [errors, setErrors] = useState<string[]>([])

  const onFieldsChanged = () => {
    if (!form) return

    setErrors(
      form
        .getFieldsError()
        .filter(({ errors }) => errors.length)
        .map((fwe) => fwe.name)
        .map((few) => `${few[0]}`)
    )
  }

  const renderTabLabel = (label, fields) => (
    <TabLabel label={label} fields={fields} fieldsWithError={errors} />
  )

  return {
    onFieldsChanged,
    renderTabLabel,
  }
}

type Props = {
  label: string
  fieldsWithError: string[]
  fields: string[]
}

const TabLabel: React.FC<Props> = ({ label, fields, fieldsWithError }) => {
  const hasError = fields.some((field) => fieldsWithError.includes(field))

  return (
    <>
      <span style={{ color: hasError ? "red" : "inherit" }}>{label}</span>{" "}
      {hasError && (
        <Tooltip title='Campos inválidos nesta aba'>
          <ExclamationCircleFilled style={{ color: "red", marginRight: 0 }} />
        </Tooltip>
      )}
    </>
  )
}
