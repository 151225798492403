import { useLogin } from "@refinedev/core"
import {
  Layout as AntdLayout,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography,
} from "antd"
import React from "react"
import "./styles.css"

const { Text, Title } = Typography

export interface ILoginForm {
  username: string
  password: string
  remember: boolean
}

export const AuthForm: React.FC = () => {
  const [form] = Form.useForm<ILoginForm>()

  const { mutate: login } = useLogin<ILoginForm>()

  const CardTitle = (
    <Title level={3} className='title'>
      Entrar no Aplicativo
    </Title>
  )

  return (
    <AntdLayout className='layout'>
      <Row
        justify='center'
        align='middle'
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className='container'>
            <div className='imageContainer'>
              <img src='./img/logo.png' alt='TCH Guindastes' />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<ILoginForm>
                layout='vertical'
                form={form}
                onFinish={(values) => {
                  login(values)
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}
              >
                <Form.Item
                  name='username'
                  label={"Usuário"}
                  rules={[{ required: true }]}
                >
                  <Input
                    size='large'
                    placeholder='seu nome de usuário'
                    autoFocus
                  />
                </Form.Item>
                <Form.Item
                  name='password'
                  label='Senha'
                  rules={[{ required: true }]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input type='password' placeholder='●●●●●●●●' size='large' />
                </Form.Item>
                <div style={{ marginBottom: "12px" }}>
                  {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Lembrar de mim
                    </Checkbox>
                  </Form.Item> */}

                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  {/* <a
                    style={{
                      float: "right",
                      fontSize: "12px",
                    }}
                    href='#'
                  >
                    Esqueci minha senha
                  </a> */}
                </div>
                <Button type='primary' size='large' htmlType='submit' block>
                  Entrar
                </Button>
              </Form>
              <div style={{ marginTop: 8 }}>
                <Text style={{ fontSize: 12 }}>
                  Não tem uma conta? Esqueceu sua senha? Entre em contato com o
                  administrador.
                </Text>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  )
}
