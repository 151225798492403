import { Edit, useForm } from "@refinedev/antd"
import { IResourceComponentsProps } from "@refinedev/core"
import React from "react"
import { ComponentGroup } from "../../entities/ComponentGroup"
import { ComponentsGroupsForm } from "./ComponentsGroupsForm"

export const ComponentsGroupsEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading } = useForm<
    any,
    any,
    ComponentGroup
  >()

  return (
    <Edit saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <ComponentsGroupsForm formProps={formProps} />
    </Edit>
  )
}
