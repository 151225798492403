import i18n from "i18next"
import Backend from "i18next-http-backend"
import { initReactI18next } from "react-i18next" // https://react.i18next.com/latest/using-with-hooks

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en"],
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json", // locale files path
    },
    defaultNS: "translation",
    ns: ["translation", "menu"],
    fallbackLng: ["en"],
    debug: true,
  })

export default i18n
