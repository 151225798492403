import { Form, Input } from "antd"
import DisabledContext from "antd/es/config-provider/DisabledContext"
import { RowSpacer } from "components/row-spacer/RowSpacer"
import React, { useContext } from "react"
import {
  PhoneMaskedInput,
  renderPhoneNumber,
} from "../../../helpers/formatters"

export const ContactForm: React.FC = () => {
  const disabled = useContext(DisabledContext)

  return (
    <>
      <RowSpacer spans={[12, 12]}>
        <Form.Item
          label='Email'
          name={["email"]}
          rules={[
            {
              type: "email",
              message: "Email inválido",
            },
          ]}
        >
          <Input style={{ textTransform: "lowercase" }} />
        </Form.Item>
        <Form.Item label='Contato' name={["contactName"]}>
          <Input style={{ textTransform: "uppercase" }} />
        </Form.Item>
      </RowSpacer>

      <RowSpacer spans={[12, 12]}>
        <Form.Item
          label='Telefone'
          name={["phoneNumber"]}
          getValueProps={(value) => ({ value: renderPhoneNumber(value) })}
        >
          <PhoneMaskedInput disabled={disabled} />
        </Form.Item>
        <Form.Item
          label='Celular'
          name={["mobileNumber"]}
          getValueProps={(value) => ({ value: renderPhoneNumber(value) })}
        >
          <PhoneMaskedInput disabled={disabled} />
        </Form.Item>
      </RowSpacer>
    </>
  )
}
