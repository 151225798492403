import {
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd"
import { BaseRecord, IResourceComponentsProps } from "@refinedev/core"
import { Space, Table } from "antd"
import React from "react"

export const ProductsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
    sorters: {
      initial: [{ field: "name", order: "asc" }],
    },
  })

  return (
    <List>
      <Table {...tableProps} rowKey='id' tableLayout='auto'>
        <Table.Column width='5rem' dataIndex='id' title='Id' />
        <Table.Column dataIndex='name' title='Nome' />

        <Table.Column
          title='Ações'
          dataIndex='actions'
          width='10rem'
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size='small' recordItemId={record.id} />
              <ShowButton hideText size='small' recordItemId={record.id} />
              <DeleteButton hideText size='small' recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
