import {
  AuthActionResponse,
  AuthBindings,
  CheckResponse,
  OnErrorResponse,
} from "@refinedev/core/dist/interfaces"
import axios from "axios"
import { User } from "./entities/User"
import { login } from "./service/auth"

export const ACCESS_TOKEN_KEY = "access-token"
export const REFRESH_TOKEN_KEY = "refresh-token"

export const authProvider: AuthBindings = {
  login: async ({ username, password }): Promise<AuthActionResponse> => {
    if (username && password) {
      try {
        const response = await login({ username, password })

        if (response?.accessToken) {
          saveTokens(response.accessToken, response.refreshToken)
          return {
            success: true,
            redirectTo: "/",
          }
        }

        return {
          success: false,
          error: new Error("Email ou senha inválidos"),
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          return {
            success: false,
            error: new Error(getErrorMessage(e.response?.status)),
          }
        }
      }
    }

    return {
      success: false,
      error: new Error("Informe o usuário e a senha"),
    }
  },

  logout: async (): Promise<AuthActionResponse> => {
    clearTokens()
    return {
      success: true,
      redirectTo: "/login",
    }
  },

  onError: async (error): Promise<OnErrorResponse> => {
    const status = error.statusCode

    if (status === 418) {
      // from interceptor
      clearTokens()
      return {
        redirectTo: "/login",
      }
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve({})
  },

  check: async (): Promise<CheckResponse> => {
    const makeError = (msg?: string) => ({
      authenticated: false,
      logout: true,
      redirectTo: "/login",
      error: new Error(msg || "Usuário não autenticado"),
    })

    const token = localStorage.getItem(ACCESS_TOKEN_KEY)

    if (!token) {
      return makeError()
    }

    const tokenExpired = token ? parseJwtExp(token) * 1000 - Date.now() : 0

    if (tokenExpired < 0) {
      return makeError()
    }

    return {
      authenticated: true,
    }
  },

  getPermissions: async (params) => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY)

    if (!token) {
      return []
    }

    const user = getUserFromToken()
    return user?.role.permissions
  },

  getIdentity: async () => getUserFromToken(),
}

export function getUserFromToken(): User | null {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY)

  if (!token) {
    return null
  }

  return parseJwtUser(token) as User
}

const parseJwtExp = (token): number => {
  try {
    return JSON.parse(atob(token.split(".")[1])).exp
  } catch (e) {
    return -1
  }
}

function parseJwtUser(token: string) {
  var base64Url = token.split(".")[1]
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join("")
  )

  return JSON.parse(jsonPayload)
}

function clearTokens() {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
  localStorage.removeItem(REFRESH_TOKEN_KEY)
}

function saveTokens(accessToken, refreshToken) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
}

function getErrorMessage(status?: number) {
  switch (status) {
    case 403:
      return "Usuário não existente ou senha incorreta"
    case undefined:
    case null:
    default:
      return undefined
  }
}
