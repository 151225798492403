import MDEditor, { commands } from "@uiw/react-md-editor"
import React from "react"
import { underline } from "./underline-command"

export const MarkdownEditor = ({ value, onChange, disabled }: any) => (
  <MDEditor
    value={value}
    onChange={onChange}
    commands={[commands.bold, commands.italic, underline]}
    preview={"edit"}
    hideToolbar={disabled}
    style={
      disabled
        ? {
            color: "rgba(0, 0, 0, 0.25)",
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          }
        : {}
    }
    previewOptions={{
      style: disabled
        ? { color: "rgba(0, 0, 0, 0.25)", backgroundColor: "inherit" }
        : {},
    }}
  />
)
