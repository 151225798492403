import { Form, FormProps, Input } from "antd"
import React from "react"
import { Accessory } from "../../entities/Accessory"
import { RowSpacer } from "../row-spacer/RowSpacer"
import { AuditPanel } from "../audit-panel/AuditPanel"

type Props = {
  formProps: FormProps<Accessory>
  disabled?: boolean
}

export const AccessoriesForm: React.FC<Props> = ({ formProps, disabled }) => (
  <Form {...formProps} layout='vertical' disabled={disabled}>
    <RowSpacer spans={[4, 20]}>
      <Form.Item label='Id' name={["id"]}>
        <Input readOnly disabled />
      </Form.Item>
      <Form.Item
        label='Name'
        name={["name"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input autoFocus style={{ textTransform: "uppercase" }} />
      </Form.Item>
    </RowSpacer>
    <RowSpacer>
      {formProps.initialValues && (
        <AuditPanel tableName='accessories' pk={formProps.initialValues.id} />
      )}
    </RowSpacer>
  </Form>
)
