import { Form, FormProps, Input } from "antd"
import { PurchaseQuotation } from "../../entities/PurchaseQuotation"
import {
  ComponentsTable,
  componentsTableValidator,
} from "../../helpers/ComponentsTable"
import { NumberEditor } from "../../helpers/NumberEditor"
import { PurchaseQuotationStatusInput } from "../../helpers/PurchaseQuotationStatus"
import { RowSpacer } from "../row-spacer/RowSpacer"

type Props = {
  formProps: FormProps<PurchaseQuotation>
  disabled?: boolean
  purchaseQuotation?: PurchaseQuotation
}
export const PurchaseQuotationsForm: React.FC<Props> = ({
  formProps,
  disabled,
  purchaseQuotation,
}) => {
  return (
    <Form {...formProps} layout='vertical'>
      <RowSpacer spans={[4, 4]}>
        <Form.Item label='Id' name='id'>
          <Input readOnly />
        </Form.Item>
        <Form.Item label='Status' name='status'>
          <PurchaseQuotationStatusInput />
        </Form.Item>
      </RowSpacer>
      <RowSpacer spans={[8, 8, 8]}>
        <Form.Item label='Pedido' name={["order", "code"]}>
          <Input readOnly />
        </Form.Item>
        <Form.Item label='Cliente' name={["order", "customer", "companyName"]}>
          <Input readOnly />
        </Form.Item>
        <Form.Item label='Fornecedor' name={["vendor", "companyName"]}>
          <Input readOnly />
        </Form.Item>
      </RowSpacer>
      <RowSpacer spans={[4]}>
        <Form.Item label='Valor total' name='totalPrice'>
          <NumberEditor readOnly={disabled} />
        </Form.Item>
      </RowSpacer>
      <RowSpacer spans={[24]}>
        <Form.Item
          label='Lista de materiais'
          name='items'
          rules={[{ validator: componentsTableValidator }]}
        >
          <ComponentsTable disabled={disabled} />
        </Form.Item>
      </RowSpacer>
    </Form>
  )
}
