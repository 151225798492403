import { PCWrapper } from "./pcwrapper"

type Group = {
  key: string
  title: string
  part: string
  children?: {
    key: string
    title: string
    place: string
  }[]
}

export const generateTreeDatasource = (
  wrapper?: PCWrapper,
  filter?: string
): Group[] => {
  if (!wrapper) return []

  const sorted = wrapper
    .toArray()
    // .sort((a, z) => a.part.localeCompare(z.part))
    // .sort((a, z) => a.place.localeCompare(z.place))
    .filter((pc) =>
      filter ? pc.part.includes(filter) || pc.place.includes(filter) : true
    )
  // .sort((a, z) =>
  //   a.component && z.component ? a.component.id - z.component.id : 0
  // )

  const result: Group[] = []

  for (const productComponent of sorted) {
    const part = productComponent.part

    let groupedPart = result.find((res) => res.part === part)

    if (!groupedPart) {
      result.push({ key: part, title: part, part, children: [] })
    }
  }

  for (const productComponent of sorted) {
    const part = productComponent.part
    const place = productComponent.place

    const groupedPart = result.find((res) => res.part === part)!
    const groupedPlace = groupedPart.children?.find((pl) => pl.place === place)

    if (!groupedPlace) {
      groupedPart.children?.push({
        key: wrapper.keyFromProductComponent(productComponent),
        title: place,
        place,
      })
    }
  }

  return result
}
