import { DownOutlined, UserOutlined, WarningFilled } from "@ant-design/icons"
import { RefineLayoutHeaderProps } from "@refinedev/antd"
import { useGetIdentity, useLogout } from "@refinedev/core"
import {
  Layout as AntdLayout,
  Avatar,
  Col,
  Dropdown,
  Row,
  Space,
  Tag,
  Tooltip,
} from "antd"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { User } from "../../entities/User"

const REVIEW_MSG = `
  Este endereço contém uma versão de testes do aplicativo da TCH guindastes. 
  Os dados aqui registrados podem ser excluidos a qualquer momento.
`

export const Header: React.FC<RefineLayoutHeaderProps> = () => {
  const [showReview, setShowReview] = useState(true)
  const { data: user } = useGetIdentity<User>()
  const shouldRenderHeader = user && (user.firstName || user.username)
  const { mutate: logout } = useLogout()

  const isProduction =
    window.location.host && window.location.host.endsWith("tchguindastes.app")

  const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    logout()
  }

  useEffect(() => {
    const blinkReview = () => {
      setShowReview(false)
      setTimeout(() => setShowReview(true), 200)
    }

    const handle = setInterval(blinkReview, 5_000)

    return () => clearInterval(handle)
  }, [])

  return shouldRenderHeader ? (
    <AntdLayout.Header
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
        color: "white",
      }}
    >
      <Row style={{ width: "100%" }}>
        <Col flex='1'>
          <Row>
            <Col lg={0}>
              <img
                src='/img/logo_small.png'
                alt='TCH Guindastes'
                style={{
                  margin: "0 auto",
                  padding: "12px 0",
                  maxHeight: "55.5px",
                }}
              />
            </Col>
            {!isProduction && showReview && (
              <Col flex={1}>
                <Space
                  direction='horizontal'
                  style={{ width: "100%", justifyContent: "center" }}
                >
                  <Tag
                    color='#cd201f'
                    icon={
                      <Tooltip title={REVIEW_MSG} trigger={"hover"}>
                        <WarningFilled />
                      </Tooltip>
                    }
                  >
                    Versão Review
                  </Tag>
                </Space>
              </Col>
            )}
          </Row>
        </Col>
        <Col>
          {user.id && (
            <Space align='center'>
              <span>{user.firstName || user.username}</span>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      label: (
                        <Link to={`/users/edit/${user.id}`}>
                          Alterar a senha
                        </Link>
                      ),
                    },
                    {
                      key: "2",
                      label: (
                        <Link to='/logout' onClick={handleLogout}>
                          Sair
                        </Link>
                      ),
                    },
                  ],
                }}
              >
                <Space>
                  <Avatar size='default' icon={<UserOutlined />} />
                  <DownOutlined />
                </Space>
              </Dropdown>
            </Space>
          )}
        </Col>
      </Row>
    </AntdLayout.Header>
  ) : null
}
