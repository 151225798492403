import { ListButton, ShowButton, useForm } from "@refinedev/antd"
import { PurchaseQuotation } from "../../entities/PurchaseQuotation"
import { Edit, SaveButton } from "@refinedev/antd"
import { RevertButton } from "../../helpers/buttons"
import { PurchaseQuotationsForm } from "./PurchaseQuotationsForm"
import { IResourceComponentsProps } from "@refinedev/core"

export const PurchaseQuotationsEdit: React.FC<
  IResourceComponentsProps
> = () => {
  const { formProps, saveButtonProps, formLoading, queryResult, form } =
    useForm<PurchaseQuotation, any, PurchaseQuotation>({ redirect: false })

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      isLoading={formLoading}
      headerButtons={[<ListButton />, <ShowButton />]}
      footerButtons={[
        <RevertButton form={form} />,
        <SaveButton onClick={() => form.submit()} />,
      ]}
    >
      <PurchaseQuotationsForm
        formProps={formProps}
        purchaseQuotation={queryResult?.data?.data}
      />
    </Edit>
  )
}
