import { Form } from "antd"
import { MarkdownEditor } from "../mdeditor/MarkdownEditor"
type Props = {
  disabled?: boolean
}

export const SpecificationForm: React.FC<Props> = ({ disabled }) => (
  <Form.Item
    label='Especificação'
    name={["specification"]}
    rules={[
      {
        required: true,
      },
    ]}
  >
    <MarkdownEditor disabled={disabled} readonly={disabled} />
  </Form.Item>
)
