import { Create, useForm } from "@refinedev/antd"
import { IResourceComponentsProps } from "@refinedev/core"
import React from "react"
import { Vendor } from "../../../entities/Vendor"
import { PartnersForm } from "../components/PartnersForm"

export const VendorsCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading } = useForm<
    Vendor,
    any,
    Vendor
  >()

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <PartnersForm formProps={formProps} auditTableName='vendors' />
    </Create>
  )
}
