import {
  AppstoreOutlined,
  FileTextOutlined,
  FormatPainterOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { useCan } from "@refinedev/core"
import { Card, Col, Row, Tag } from "antd"
import { Children, PropsWithChildren } from "react"
import { useNavigate } from "react-router-dom"
import { Order } from "../../../entities/Order"
import { OrderStatus } from "../../../entities/OrderStatus"
import { StatusTargetTag, TargetTag } from "../../../helpers/StatusTargetTag"

type OrderCardProps = {
  order: Order
  ordersStatuses: OrderStatus[]
}

export const OrderCard: React.FC<OrderCardProps> = ({
  order,
  ordersStatuses,
}) => {
  const navigate = useNavigate()
  const { data: editOrderPermission } = useCan({
    resource: "orders",
    action: "list",
  })

  const atReviewStage = order.status.review

  return (
    <Card
      style={{ width: "100", marginBottom: "10px" }}
      bodyStyle={{ padding: "5px", boxShadow: "1px 1px 1px gray" }}
      hoverable
      onClick={() =>
        editOrderPermission?.can && navigate(`/orders/show/${order.id}`)
      }
    >
      <Row>
        <Col flex='1'>
          <FileTextOutlined /> #{order.code}
        </Col>
        <Col flex='none'>
          <StatusTargetTag order={order} />
        </Col>
      </Row>
      <Row>
        <Col
          flex='1'
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingRight: ".5em",
          }}
        >
          <UserOutlined /> {order.customer.companyName}
        </Col>
        <Col flex='none'>{atReviewStage || <TargetTag order={order} />}</Col>
      </Row>
      <Row>
        <Col
          flex='1'
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingRight: ".5em",
          }}
        >
          <AppstoreOutlined /> {order?.product?.name}{" "}
        </Col>
        <Col flex='none'>
          {order?.color && (
            <Tag icon={<FormatPainterOutlined />} style={{ width: "8em" }}>
              {order?.color?.name}
            </Tag>
          )}
        </Col>
      </Row>
    </Card>
  )
}

type OrderStatusBoardProps = {
  orderStatus: OrderStatus
}

const OrderStatusBoard: React.FC<PropsWithChildren<OrderStatusBoardProps>> = ({
  orderStatus,
  children,
}) => {
  const count = Children.count(children)

  return (
    <Col xs={24} sm={12} md={8}>
      <Card
        title={`${orderStatus.sequence} ${orderStatus.name}`}
        extra={`${count ? count : "-"}`}
        style={{
          minHeight: "20em",
          backgroundColor: "rgb(213,213,213)",
          maxHeight: "15em",
          boxShadow: "3px 3px 5px gray",
        }}
        bodyStyle={{
          padding: "12px",
          maxHeight: "16em",
          overflowY: "auto",
        }}
        headStyle={{
          padding: "0 12px",
          minHeight: "40px",
          color: "white",
          backgroundColor: orderStatus.color,
        }}
      >
        {children}
      </Card>
    </Col>
  )
}

type OrderBoardProps = {
  orders: Order[]
  ordersStatuses: OrderStatus[]
  loading: boolean
}

export const OrderBoard: React.FC<OrderBoardProps> = ({
  orders,
  ordersStatuses,
  loading,
}) => {
  if (loading) {
    return (
      <Row gutter={[15, 15]}>
        {new Array(6).fill(1).map(() => (
          <Col md={8}>
            <Card loading />
          </Col>
        ))}
      </Row>
    )
  }

  const byOrderStatus = ordersStatuses.map((os) => ({
    ...os,
    orders: orders.filter((order) =>
      order.status ? order.status.id === os.id : false
    ),
  }))

  return (
    <Row gutter={[15, 15]}>
      {byOrderStatus.map((bos) => (
        <OrderStatusBoard orderStatus={bos}>
          {bos.orders.map((order) => (
            <OrderCard order={order} ordersStatuses={ordersStatuses} />
          ))}
        </OrderStatusBoard>
      ))}
    </Row>
  )
}
