import { useList } from "@refinedev/core"
import { Form, FormProps } from "antd"
import { ValidatorRule } from "rc-field-form/lib/interface"
import { useCallback } from "react"
import { OrderStatus } from "../../entities/OrderStatus"
import { Product, ProductOrderStatus } from "../../entities/Product"
import { OrderStatusEdit } from "../orderstatusedit/OrderStatusEdit"

type Props = {
  formProps: FormProps<Product>
  disabled?: boolean
}

export const OrdersStatusesForm: React.FC<Props> = ({
  formProps,
  disabled,
}) => {
  const { data: orderStatuses } = useList<OrderStatus>({
    resource: "ordersStatuses",
  })

  const orderStatusValidator: ValidatorRule["validator"] = useCallback(
    (rule, value: ProductOrderStatus[], callback) => {
      if (!orderStatuses || !orderStatuses.data || !value) {
        return callback()
      }

      const hasAllFilled = value.every((v) => v.deadline !== undefined)
      const hasAllAdded = orderStatuses.data.length === value.length

      if (!hasAllAdded || !hasAllFilled) {
        return callback("Preencha todos os status")
      }

      callback()
    },
    [orderStatuses]
  )

  return (
    <Form.Item
      label='Prazos'
      name='orderStatuses'
      rules={[
        {
          required: true,
        },
        {
          validator: orderStatusValidator,
        },
      ]}
    >
      <OrderStatusEdit orderStatuses={orderStatuses} disabled={disabled} />
    </Form.Item>
  )
}
