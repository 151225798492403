import { axiosInstance } from "../axiosInstance"
import { stringify } from "@refinedev/simple-rest"

const downloadReport = async (type: string, params: Record<string, any>) => {
  const { data } = await axiosInstance.get(
    `/reports/${type}?${stringify(params)}`,
    { responseType: "blob" }
  )

  const blob = new Blob([data], { type: "application/pdf" })

  var downloadLink = document.createElement("a")
  downloadLink.target = "_blank"

  // create an object URL from the Blob
  var URL = window.URL || window.webkitURL
  var downloadUrl = URL.createObjectURL(blob)

  // set object URL as the anchor's href
  downloadLink.href = downloadUrl

  // append the anchor to document body
  document.body.appendChild(downloadLink)

  // fire a click event on the anchor
  downloadLink.click()

  // cleanup: remove element and revoke object URL
  document.body.removeChild(downloadLink)
  URL.revokeObjectURL(downloadUrl)
}

export const downloadPurchaseQuotationsReport = (id: number) =>
  downloadReport("purchaseQuotation", { id })
