import {
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd"
import { BaseRecord, IResourceComponentsProps } from "@refinedev/core"
import { Space, Table, Tag } from "antd"
import React from "react"
import { Vendor } from "../../entities/Vendor"

export const ComponentsGroupsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
    sorters: {
      initial: [{ field: "name", order: "asc" }],
    },
  })

  return (
    <List>
      <Table
        {...tableProps}
        rowKey='id'
        expandable={{
          rowExpandable: () => true,
          onExpand: (expanded, record) => null,
        }}
      >
        <Table.Column dataIndex='id' title='Id' sorter />
        <Table.Column dataIndex='name' title='Nome' sorter />
        <Table.Column
          dataIndex={["vendors"]}
          title='Fornecedores'
          responsive={["lg"]}
          render={(value: Vendor[]) => (
            <Space.Compact size='small'>
              {value.map((v) => (
                <Tag color='blue'>{v.companyName}</Tag>
              ))}
            </Space.Compact>
          )}
        />
        <Table.Column
          title='Actions'
          dataIndex='actions'
          width='10rem'
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size='small' recordItemId={record.id} />
              <ShowButton hideText size='small' recordItemId={record.id} />
              <DeleteButton hideText size='small' recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
