import { AxiosInstance } from "axios"
import { ACCESS_TOKEN_KEY } from "../../../authProvider"

const accessTokenInterceptor = (request) => {
  const authToken = localStorage.getItem(ACCESS_TOKEN_KEY)

  const authExists = request.headers && request.headers["Authorization"]

  if (!authExists) {
    request.headers = {
      ...(request.headers || {}),
      Authorization: authToken ? `Bearer ${authToken}` : "",
    }
  }
  return request
}

export const addAccessTokenInterceptor = (axiosInstance: AxiosInstance) =>
  axiosInstance.interceptors.request.use(accessTokenInterceptor)
