import { Show } from "@refinedev/antd"
import { IResourceComponentsProps } from "@refinedev/core"
import React from "react"
import { PartnersForm } from "../components/PartnersForm"
import { useShowForm } from "../../../helpers/useShowForm"

export const CustomersShow: React.FC<IResourceComponentsProps> = () => {
  const { formProps, formLoading } = useShowForm()

  return (
    <Show isLoading={formLoading}>
      <PartnersForm formProps={formProps} disabled auditTableName='customers' />
    </Show>
  )
}
