import { ClockCircleOutlined, TrophyOutlined } from "@ant-design/icons"
import { Tag, Tooltip } from "antd"
import { Order } from "../entities/Order"
import dayjs from "dayjs"

const statusTargetColor = (statusTargetDiff: number) =>
  statusTargetDiff === 0
    ? "#dbca01"
    : statusTargetDiff > 0
    ? "#c30310"
    : "default"

const statusTargetDiff = (statusTargetDiff: number) =>
  `${statusTargetDiff > 0 ? "+" : ""}${statusTargetDiff}`

const targetColor = (targetDiff: number) =>
  targetDiff >= 0 ? "#c30310" : targetDiff >= -5 ? "#dbca01" : "default"

const statusTargetTooltip = (statusTargetDiff: number) =>
  statusTargetDiff === 0
    ? "Este é o último dia para este pedido sair desta etapa"
    : statusTargetDiff > 0
    ? `Este pedido está atrasado para esta etapa em ${statusTargetDiff} ${
        statusTargetDiff > 1 ? "dias" : "dia"
      }`
    : `Este pedido está dentro do prazo para esta etapa`

type Props = {
  order: Order
}

export const StatusTargetTag: React.FC<Props> = ({ order }) => (
  <Tooltip title={statusTargetTooltip(order.statusTargetDiff)}>
    <Tag
      icon={<ClockCircleOutlined />}
      color={statusTargetColor(order.statusTargetDiff)}
      style={{
        width: "8em",
        fontWeight: order.statusTargetDiff >= 0 ? "bold" : "",
      }}
    >
      {dayjs(order.statusTargetDate).format("DD/MM")} (
      {statusTargetDiff(order.statusTargetDiff)})
    </Tag>
  </Tooltip>
)

export const TargetTag: React.FC<Props> = ({ order }) => (
  <Tag
    icon={<TrophyOutlined />}
    color={targetColor(order.targetDiff)}
    style={{ width: "8em" }}
  >
    {dayjs(order.targetDate).format("DD/MM/YYYY")}
  </Tag>
)
