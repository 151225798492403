import { Create, useForm } from "@refinedev/antd"
import { IResourceComponentsProps } from "@refinedev/core"
import React from "react"
import { PartnersForm } from "../components/PartnersForm"

export const CustomersCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading } = useForm()

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <PartnersForm formProps={formProps} auditTableName='customers' />
    </Create>
  )
}
