import { GetListResponse } from "@refinedev/core"
import { InputNumber, Table } from "antd"
import React, { useCallback, useMemo } from "react"
import { OrderStatus } from "../../entities/OrderStatus"
import { ProductOrderStatus } from "../../entities/Product"

type Props = {
  value?: ProductOrderStatus[]
  onChange?: Function
  orderStatuses: GetListResponse<OrderStatus> | undefined
  disabled?: boolean
}

export const OrderStatusEdit: React.FC<Props> = ({
  value,
  onChange,
  orderStatuses,
  disabled,
}) => {
  const handleChange = useCallback(
    (record: ProductOrderStatus) => {
      const dataToChange = [
        ...(value || []).filter(
          (v) => v.orderStatusId !== record.orderStatusId
        ),
        {
          id: record.id,
          orderStatusId: record.orderStatusId,
          deadline: record.deadline,
        },
      ]

      if (onChange) onChange(dataToChange)
    },
    [value, onChange]
  )

  const columns = [
    { title: "Id", dataIndex: "orderStatusId", key: "orderStatusId" },
    { title: "Status", dataIndex: "name", key: "name" },
    {
      title: "Prazo",
      dataIndex: "deadline",
      key: "deadline",
      render: (_: any, record: ProductOrderStatus) => (
        <InputNumber<number>
          value={record.deadline}
          onChange={(value) =>
            handleChange({ ...record, deadline: value ?? undefined })
          }
          min={0}
          disabled={disabled}
        />
      ),
    },
  ]

  const dataSource = useMemo(() => {
    if (!orderStatuses) {
      return []
    }

    return orderStatuses.data.map(
      (orderStatus: OrderStatus, index: number) => ({
        id: value?.find((v) => v.orderStatusId === orderStatus.id)?.id,
        key: index,
        name: orderStatus.name,
        orderStatusId: orderStatus.id,
        deadline: value?.find((v) => v.orderStatusId === orderStatus.id)
          ?.deadline,
      })
    )
  }, [orderStatuses, value])

  return !!dataSource ? (
    <Table
      dataSource={dataSource as unknown as any[]}
      columns={columns}
      size='small'
      pagination={false}
    />
  ) : null
}
