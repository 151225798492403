import { Show } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import React from "react";
import { User } from "../../entities/User";
import { useShowForm } from "../../helpers/useShowForm";
import { UsersForm } from "./UsersForm";



export const UsersShow: React.FC<IResourceComponentsProps> = () => {
  const { formProps, formLoading } = useShowForm<User>();

  return (
    <Show isLoading={formLoading}>
      <UsersForm formProps={formProps} disabled />
    </Show>
  );
};
