import { Edit, useForm } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import React from "react";
import { Accessory } from "../../entities/Accessory";
import { AccessoriesForm } from "./AccessoriesForm";

export const AccessoriesEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading } = useForm<any, any, Accessory>();

  return (
    <Edit saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <AccessoriesForm formProps={formProps} />
    </Edit>
  );
};
