import { useForm } from "@refinedev/antd"
import { useShow } from "@refinedev/core"

export const useShowForm = <T extends {}>() => {
  const { queryResult } = useShow<T>()
  const { formProps, formLoading } = useForm<T, any, T>()

  formProps.initialValues = queryResult?.data?.data

  return { formProps, formLoading, refetch: queryResult.refetch }
}
