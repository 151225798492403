import axios, { AxiosInstance } from "axios"
import createAuthRefreshInterceptor from "axios-auth-refresh"
import { REFRESH_TOKEN_KEY, ACCESS_TOKEN_KEY } from "../../../authProvider"
import { refresh } from "../../auth"
import { axiosInstance } from ".."
import { useEffect } from "react"

const refreshTokens = async () => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY)

  if (!refreshToken) {
    console.error("No refresh token!")
    return
  }

  const response = await refresh({ refreshToken })

  if (response?.accessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, response.accessToken)
    localStorage.setItem(REFRESH_TOKEN_KEY, response.refreshToken)
  }

  return response.accessToken
}

export const useTokensRefresher = () => {
  useEffect(() => {
    const interval = setInterval(() => refreshTokens(), 1000 * 60 * 5)
    return () => clearInterval(interval)
  })

  return null
}

const refreshAuthLogic = async (failedRequest) => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY)

  if (refreshToken) {
    try {
      const accessToken = await refreshTokens()

      if (accessToken) {
        failedRequest.response.config.headers["Authorization"] =
          "Bearer " + accessToken
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.status === 401) {
          return Promise.reject({
            statusCode: 418,
          })
        }
      }
    }

    return
  }
}

export const addRefreshTokenInterceptor = (axiosIntance: AxiosInstance) =>
  createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic)
