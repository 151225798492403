import { InputNumber } from "antd"
import { stringToFloat } from "./numbers"

type Props = {
  value?: any
  onChange?: (value: any) => void
  readOnly?: boolean
  disabled?: boolean
}
export const NumberEditor: React.FC<Props> = ({
  value,
  onChange,
  readOnly,
  disabled,
}) => {
  return (
    <InputNumber<number>
      style={{ width: "100%" }}
      value={value}
      onPressEnter={(e) =>
        onChange && onChange(stringToFloat(e.target["value"]))
      }
      onBlur={(e) => {
        onChange && onChange(stringToFloat(e.target.value))
      }}
      onFocus={(e) => e.target.setSelectionRange(0, 999)}
      min={0}
      precision={2}
      step={0.1}
      decimalSeparator=','
      controls={false}
      disabled={readOnly || disabled}
    />
  )
}
